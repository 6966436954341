<template>
    <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
        mdi-qrcode
      </v-icon>
    </template>

    <span>C&oacute;digo QR</span>

    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" width="250px" persistent>
      <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="text-subtitle-1 text-md-h5">C&oacute;digo QR</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn class="hidden-sm-and-down" icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="pt-2">
            <v-skeleton-loader v-if="loading" type="image" height="200px" width="200px"></v-skeleton-loader>
            <v-img id="div-qr" v-else :src="qrCode" :lazy-src="qrCode" height="200px" width="200px"></v-img>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="downloadSVG" :disabled="loading">
                Descargar
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-tooltip>
</template>
<script>
import userService from "@/providers/UsersService";

export default {
    name: "QR2Fa",
    props: ["user"],
    data(){
        return {
            loading: false,
            dialog: false,
            qrCode: "",
        }
    },
    methods: {
        openDialog(){
            this.dialog = true;
            this.loadQr();
        },
        loadQr(){
            this.loading = true;
            userService.getQR2FaId(this.user.id).then(resp=>{
              this.qrCode = `http://api.qrserver.com/v1/create-qr-code/?data=${resp.data.value}&size=200x200`;
            }).catch(err=>console.log(err)).finally(()=>{
                this.loading = false;
            });
        },
        
        downloadSVG() {
          try {
            fetch(this.qrCode).then(response=>{
              response.blob().then(blob=>{
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${this.user.name} 2fa.png`;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              });
            });
          } catch (error) {
            console.error('Error downloading the image:', error);
          }
        },
    }
}
</script>
<style>
</style>