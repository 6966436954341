<template>
    <UpdateHospital :id="$route.params.id"/>
</template>
<script>
import UpdateHospital from "@/components/admin/modules/gest-hospital/UpdateHospital";

export default {
    name: "UpdateHospitalView",
    title: "Actualizar Hospital | Baja California Health Tourism",
    components: {
        UpdateHospital
    }
}
</script>