<template>
  <div>
    <TwoFactorComponent />
  </div>
</template>

<script>
import TwoFactorComponent from "@/components/auth/TwoFactorComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "TwoFactorView",
  title: "Autenticación de segundo factor | Baja California Health Tourism",
  components: {
    TwoFactorComponent,
  },
  mounted() {
    changeInformationSEO();
  }
};
</script>
