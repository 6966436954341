<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
        mdi-file-document
      </v-icon>
    </template>

    <span>Certificaciones</span>

    <v-dialog v-model="dialog" fullscreen persistent>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="text-subtitle-1 text-md-h5">Certificaciones</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn class="hidden-sm-and-down" icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <div class="pl-2 pr-2">
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <v-date-picker v-model="expire" :rules="rules" :disabled="saveChanges"></v-date-picker>
                </v-col>
                <v-col lg="8" md="4" sm="12">
                  <v-row>
                    <v-col cols="12">
                      <v-select v-model="certification" :items="items" :disabled="saveChanges" :rules="rules"
                        item-value="id" item-text="name" outlined label="Certificaciones"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input :rules="rulesImages" show-size small-chips accept="image/png, image/jpeg, .pdf"
                        :disabled="saveChanges" label="Documento" truncate-length="50" v-model="file"></v-file-input>
                    </v-col>
                    <v-col cols="12">
                      <v-btn :disabled="!valid || saveChanges" color="primary" :loading="saveChanges"
                        @click="saveCertifications">
                        Guardar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1" class="font-weight-bold"></v-col>
                    <v-col cols="3" class="font-weight-bold">Certificaci&oacute;n</v-col>
                    <v-col cols="2" class="font-weight-bold">Fecha de expiraci&oacute;n</v-col>
                    <v-col cols="2" class="font-weight-bold">Fecha de subida</v-col>
                    <v-col cols="2" class="font-weight-bold">Subido por</v-col>
                    <v-col cols="2" class="font-weight-bold">Acciones</v-col>
                  </v-row>
                  <v-row v-for="itemImage in certificationsEntity" :key="itemImage.id">
                    <ItemCertificationDoctor :item="itemImage" @reload="loadDoctorCertifications" :deleteUrl="deleteUrl"/>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-form>
      </v-card>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </v-tooltip>
</template>

<script>
import axios from "axios";
import ItemCertificationDoctor from "@/components/certifications/ItemCertificationDoctor";

export default {
  name: "Certification",
  props: ["entity", "url", "deleteUrl"],
  components: {
    ItemCertificationDoctor
  },
  data() {
    return {
      dialog: false,
      keywords: "",
      certifications: [],
      documents: [],
      certificationsEntity: [],
      file: null,
      certification: null,
      expire: new Date(),
      saveChanges: false,
      snackBar: false,
      snackText: "",
      rules: [
        v => !!v || 'El campo es requerido'
      ],
      rulesImages: [
        v => !!v || 'El campo es requerido',
        v => v?.size < 2097152 || 'Tamaño máximo 2mb'
      ],
      valid: false,
    }
  },
  computed: {
    items() {
      return this.certifications.filter(e => !this.certificationsEntity.find(f => f.certification_id === e.id));
    },
    defaultDate() {
      const today = new Date();
      const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
      const month = today.getMonth() < 9 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
      return `${today.getFullYear()}-${month}-${day}`;
    }
  },

  methods: {
    loadDoctorCertifications() {
      axios.get(`${process.env.VUE_APP_BHC_BASE_URL}/certifications/doctors/${this.entity.id}`).then(resp => {
        this.certificationsEntity = resp.data.value;
      })
    },
    getCertifications() {
      axios.get(`${process.env.VUE_APP_BHC_BASE_URL}/view-list/certifications`).then(resp => {
        this.certifications = resp.data.value;
      })
    },
    openDialog() {
      this.expire = this.defaultDate;
      this.getCertifications();
      this.loadDoctorCertifications();
      this.certificationsEntity = this.entity.certifications ?? [];
      this.file = null;
      this.certification = null;
      this.expire = this.defaultDate;
      this.dialog = true;
      this.valid = false;
    },
    close() {
      this.file = null;
      this.certification = null;
      this.expire = this.defaultDate;
      this.dialog = false;
      this.valid = false;
      this.$refs.form.reset();
    },

    reset() {
      this.file = null;
      this.certification = null;
      this.expire = this.defaultDate;
      this.valid = false;
      this.dialog = false;
      this.$refs.form.reset();
      this.$emit('reload');
    },

    saveCertifications() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.saveChanges = true;
        const formData = new FormData();
        formData.append("id", this.entity.id);
        formData.append("certification", this.certification);
        formData.append("file", this.file);
        formData.append("expire", this.expire);
        axios.post(this.url, formData,
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Token-Google-Authenticator': sessionStorage.getItem('2fa')
              }
            }
        ).then(resp => {
          if (resp.data.success) {
            this.file = null;
            this.certification = null;
            this.expire = this.defaultDate;
            this.valid = false;
            this.saveChanges = false;
            this.snackText = 'Certificación insertada con éxito';
            this.loadDoctorCertifications();
          }
          if(resp.status === 400) {
            this.snackText = resp.data.message;
          }
        }).catch(err => console.log(err)).finally(() => {
          this.saveChanges = false;
          this.snackBar = true;
        });
      }
    }
  }
}
</script>

<style>
.a-custom {
  margin-right: 1.6rem;
  text-decoration: none;
}

.i-custom {
  color: #fff !important;
  font-size: 20px !important;
}
</style>
