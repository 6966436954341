<template>
    <UpdateRestaurant :id="$route.params.id"/>
</template>
<script>
import UpdateRestaurant from "@/components/admin/modules/gest-restaurant/UpdateRestaurant";

export default {
    name: "UpdateRestaurantView",
    title: "Actualizar Restaurante | Baja California Health Tourism",
    components: {
        UpdateRestaurant
    }
}
</script>