<template>
    <UpdateLaboratory :id="$route.params.id"/>
</template>
<script>
import UpdateLaboratory from "@/components/admin/modules/gest-laboratory/UpdateLaboratory";

export default {
    name: "UpdateLaboratoryView",
    title: "Actualizar Laboratorio | Baja California Health Tourism",
    components: {
        UpdateLaboratory
    }
}
</script>