<template>
    <AddHospital/>
</template>
<script>
import AddHospital from "@/components/admin/modules/gest-hospital/AddHospital";

export default {
    name: "AddHospitalView",
    title: "Añadir Hospital | Baja California Health Tourism",
    components: {
        AddHospital
    }
}
</script>