<template>
    <v-row>
        <v-col cols="12" md="6">
            <v-text-field dense 
                v-model="property_name" 
                :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                :loading="loadingData"
                label="Nombre(s)">
            </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field dense 
                v-model="first_last_name" 
                :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                :loading="loadingData"
                label="Primer Apellido">
            </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field dense 
                v-model="second_lastname" 
                :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                :loading="loadingData"
                label="Segundo Apellido">
            </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
            <v-select dense 
                      v-model="gender"
                      :items="genders"
                      item-text="value"
                      item-value="key"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      label="Género del Representante Legal">
            </v-select>
        </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="other_option"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX') || gender!= 'O'"
                      :loading="loadingData"
                      label="Escribe la otra opción">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="rfc"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      :rules="[v=> !v || !(/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(v)) || 'RFC incorrecto']"
                      label="RFC del representante legal y/o propietario">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="ret"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      type="number"
                      label="Número de folio de Registro Estatal de Turismo (RET)">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field-simplemask v-model="whatsapp" label="Whatsapp del establecimiento (si aplica)" v-bind:properties="{
                              dense: true,
                              prefix: '+1',
                              suffix: '',
                              readonly: false,
                              outlined: false,
                              clearable: true,
                              disabled: saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX'),
                              placeholder: '',
                              required: true,
                              loading: loadingData
                            }" v-bind:options="{
                              inputMask: '(###) ###-####',
                              outputMask: '##########',
                              empty: null,
                              applyAfter: false,
                              alphanumeric: false,
                              lowerCase: false,
                            }" v-bind:focus="focus" v-on:focus="focus = false" />
      </v-col>
      <v-col cols="12">
        <v-card-text>D&iacute;as laborables: </v-card-text>
        <v-checkbox v-model="monday" label="Lunes"></v-checkbox>
        <v-checkbox v-model="tuesday" label="Martes"></v-checkbox>
        <v-checkbox v-model="wednesday" label="Miércoles"></v-checkbox>
        <v-checkbox v-model="thursday" label="Jueves"></v-checkbox>
        <v-checkbox v-model="friday" label="Viernes"></v-checkbox>
        <v-checkbox v-model="saturday" label="Sábado"></v-checkbox>
        <v-checkbox v-model="sunday" label="Domingo"></v-checkbox>
      </v-col>
      <v-col cols="12" md="6">
        <v-select dense
                  v-model="comercial_rotate"
                  :items="gires"
                  :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                  :loading="loadingData"
                  label="Favor de indicar el giro comercial del establecimiento">
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="other_option_form"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX') || comercial_rotate!= 'Otro'"
                      :loading="loadingData"
                      label="Escribe la otra opción">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select dense
                  v-model="cian_key"
                  :items="cian_keys"
                  :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                  :loading="loadingData"
                  label="Favor de indicar la Clave SCIAN - Descripción de la actividad">
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="attention_medic"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      label="Favor de indicar los tratamientos médicos que ofrece">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="bibliographic"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      label="Favor de indicar una breve reseña biográfica del establecimiento">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select dense
                  v-model="english"
                  :items="englishItems"
                  :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                  :loading="loadingData"
                  label="¿Cuenta con personal que hable inglés?">
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select dense
                  v-model="english_level"
                  :items="englishLevelItems"
                  :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX') || english != 'Sí'"
                  :loading="loadingData"
                  label="Indica la proficiencia de inglés convencional">
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="professional_organism"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      label="Si pertenece a algún organismo profesional, indicar el nombre(colegio, asociación mexicana o extranjera)">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="tiktok_profile"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      label="Si cuenta con TikTok, favor de ingresar el enlace en el siguiente apartado">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select dense
                  v-model="integral_services"
                  :items="integralServicesItems"
                  :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                  :loading="loadingData"
                  label="¿Ofrece algún servicio integral? es decir… transporte desde su arribo al aeropuerto, hospedaje y/o alimentación, adicional al tratamiento requerido">
        </v-select>
      </v-col>
      <v-col cols="12">
        <v-card-text>Si contest&oacute; que s&iacute; a la pregunta anterior, favor de indicar qu&eacute; servicio tur&iacute;stico ofrece adicional al tratamiento requerido: </v-card-text>
        <v-checkbox :disabled="integral_services != 'Sí'" v-model="hotel" label="Hotel"></v-checkbox>
        <v-checkbox :disabled="integral_services != 'Sí'" v-model="transportation" label="Transporte"></v-checkbox>
        <v-checkbox :disabled="integral_services != 'Sí'" v-model="restaurant" label="Restaurante"></v-checkbox>
        <v-checkbox :disabled="integral_services != 'Sí'" v-model="touroperator" label="Touroperador"></v-checkbox>
        <v-checkbox :disabled="integral_services != 'Sí'" v-model="house" label="Casa de recuperación"></v-checkbox>
        <v-checkbox :disabled="integral_services != 'Sí'" v-model="house1" label="Casa de rehabilitación"></v-checkbox>
        <v-checkbox :disabled="integral_services != 'Sí'" v-model="broker" label="Facilitador médico - broker"></v-checkbox>
        <v-checkbox :disabled="integral_services != 'Sí'" v-model="not" label="No, con ninguno"></v-checkbox>
        <v-checkbox :disabled="integral_services != 'Sí'" v-model="not_interest" label="No, pero me interesa conocer la oferta de servicios turísticos"></v-checkbox>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="comercial_name"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      label="Nombre comercial del servidor turístico con quien tiene la alianza comercial (si aplica)">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select dense
                  v-model="is_member"
                  :items="isMemberItems"
                  :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                  :loading="loadingData"
                  label="Favor de indicar si es miembro vigente de alguna: Asociación, Colegio, Consejo de salud, por mencionar algunos">
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="others_name"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX') || is_member != 'Sí'"
                      :loading="loadingData"
                      label="Si, contestó que sí a la pregunta anterior, favor de mencionar el o los nombres de: Asociación, Colegio, Consejo, por mencionar algunos, de los cuales es miembro vigente (si aplica).">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select dense
                  v-model="is_accredit"
                  :items="isAccreditItems"
                  :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                  :loading="loadingData"
                  label="Favor de indicar si cuenta con alguna certificación o acreditación de salud nacional y/o internacional">
        </v-select>
      </v-col>
      <v-col cols="12">
        <v-card-text>Si contest&oacute; que s&iacute; a la pregunta anterior, favor de indicar el nombre de o las certificaciones / acreditaciones de salud nacionales o internacionales vigentes con las que cuenta:</v-card-text>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="JCI" label="Joint Commission International"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="CNSG" label="Consejo Nacional de Salubridad General"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="GHA" label="Global Health Asociation (GHA)"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="CEHC" label="Canadian Healthcare Council (CEHC)"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="SRC" label="Surgical Review Corparation (SRC) - Networking Excellence"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="SRCCE" label="SRC- Center of Excellence"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="SRCMS" label="SRC- Master Surgeon"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="SRCCS" label="SRC- Care Specialist"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="CAMN" label="Certificación de la Autoridad Marian Noruega"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="STSM" label="Sello de Turismo de Salud de México"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="STI" label="Sello de Turismo Incluyente"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="ISO9001" label="International Organization for Standardization (ISO) 9001"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="ISO27001" label="ISO 27001"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="ISO45001" label="ISO 45001"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="ISO13485" label="ISO 13485"></v-checkbox>
        <v-checkbox :disabled="is_accredit != 'Sí'" v-model="other_option_select" label="Otro"></v-checkbox>
      </v-col>
      <v-col cols="12" md="6">
        <v-select dense
                  v-model="repssabi_coepris"
                  :items="repssabiCoeprisItems"
                  :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                  :loading="loadingData"
                  label="Favor de indicar si cuenta con la Constancia del Registro de Establecimientos Prestadores de Servicios de Salud y Bienestar (REPSSABI) de la COEPRIS">
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="folio_repssabi_coepris"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      type="number"
                      label="Favor de indicar el número de Folio de la Constancia del REPSSABI de la COEPRIS">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field dense
                      v-model="date_repssabi_coepris"
                      :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                      :loading="loadingData"
                      type="date"
                      label="Favor de indicar el número la fecha de vencimiento de la Constancia del REPSSABI de la COEPRIS">
        </v-text-field>
      </v-col>
    </v-row>
</template>

<script>
export default {
    name: "FormFieldStablishment",
    props: {
        entity: {
            type: Object,
            default: undefined
        },
        saveChanges: {
            type: Boolean,
            required: true,
            default: false,
        },
        loadingData: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            focus: false,
            englishLevelItems: [
                "Básico",
                "Intermedio",
                "Avanzado",
                "Bilingüe",
                "Nulo"
            ],
            repssabiCoeprisItems: [
                "Sí",
                "No",
                "En trámite"
            ],
            isAccreditItems: [
                "Sí",
                "No",
                "En trámite"
            ],
            isMemberItems: [
                "Sí",
                "No",
                "En trámite"
            ],
            integralServicesItems: [
                "Sí",
                "No",
                "No, pero me interesa hacer sinergia con prestadores de servicios turísticos "
            ],
            englishItems: [
                "Sí",
                "No"
            ],
            cian_keys: [
                "621111 Consultorios de medicina general del sector privado",
                "621112 Consultorios de medicina general del sector público",
                "621113 Consultorios de medicina especializada del sector privado",
                "621114 Consultorios de medicina especializada del sector público",
                "621115 Clínicas de consultorios médicos del sector privado",
                "621116 Clínicas de consultorios médicos del sector público",
                "621211 Consultorios dentales del sector privado",
                "621212 Consultorios dentales del sector público",
                "621311 Consultorios de quiropráctica del sector privado",
                "621312 Consultorios de quiropráctica del sector público",
                "621320	Consultorios de optometría",
                "621331	Consultorios de psicología del  sector privado",
                "621332	Consultorios de psicología del  sector público",
                "621341	Consultorios del sector privado de audiología y de terapia ocupacional, física y del lenguaje",
                "621342	Consultorios del sector público de audiología y de terapia ocupacional, física y del lenguaje",
                "621391	Consultorios de nutriólogos y dietistas del sector privado",
                "621392	Consultorios de nutriólogos y dietistas del sector público",
                "621398	Otros consultorios del sector privado para el cuidado de la salud",
                "621399	Otros consultorios del sector público para el cuidado de la salud",
                "621411	Centros de planificación familiar del sector privado",
                "621412	Centros de planificación familiar del sector público",
                "621421	Centros del sector privado de atención médica externa para enfermos mentales y adictos",
                "621422	Centros del sector público de atención médica externa para enfermos mentales y adictos",
                "621491	Otros centros del sector privado para la atención de pacientes que no requieren hospitalización",
                "621492	Otros centros del sector público para la atención de pacientes que no requieren hospitalización",
                "621511	Laboratorios médicos y de diagnóstico del sector privado",
                "621511	Laboratorios médicos y de diagnóstico del sector privado (Sólo gabinete de rayos x o medicina nuclear para tratamiento o diagnóstico)",
                "621512	Laboratorios médicos y de diagnóstico del sector público",
                "621512	Laboratorios médicos y de diagnóstico del sector público (Sólo gabinete de rayos x o medicina nuclear para tratamiento o diagnóstico)",
                "621910	Servicios de ambulancias",
                "621991	Servicios de bancos de órganos, bancos de sangre y otro servicios auxiliares al tratamiento médico prestados por el sector privado",
                "611992	Servicios de bancos de órganos, bancos de sangre y otro servicios auxiliares al tratamiento médico prestados por el sector público",
                "622111	Hospitales generales del sector privado",
                "622112	Hospitales generales del sector público",
                "622211	Hospitales psiquiátricos y para el tratamiento por adicción del sector privado",
                "622212	Hospitales psiquiátricos y para el tratamiento por adicción del sector público",
                "622311	Hospitales del sector privado de otras especialidades médicas",
                "622312	Hospitales del sector público de otras especialidades médicas",
                "623111	Residencias del sector privado con cuidados de enfermeras para enfermos convalecientes, en rehabilitación, incurables y terminales",
                "623112	Residencias del sector público con cuidados de enfermeras para enfermos convalecientes, en rehabilitación, incurables y terminales",
                "623211	Residencias del sector privado para el cuidado de personas con problemas de retardo mental",
                "623212	Residencias del sector público para el cuidado de personas con problemas de retardo mental",
                "623221	Residencias del sector privado para el cuidado de personas con problemas de trastorno mental y adicción",
                "623222	Residencias del sector público para el cuidado de personas con problemas de trastorno mental y adicción",
                "623311	Asilos y otras residencias del sector privado para el cuidado de ancianos",
                "623312	Asilos y otras residencias del sector público para el cuidado de ancianos",
                "624111	Servicios de orientación y trabajo social para la niñez y la juventud prestados por el sector privado",
                "624121	Centros del sector privado dedicados a la atención y cuidado diurno de ancianos y discapacitados",
                "46411  Comercio al por menor de productos farmacéuticos y naturistas",
                "464121 Comercio al por menor de lentes",
                "465911 Comercio al por menor de mascotas",
                "541    Servicios profesionales, científicos y técnicos",
                "713998 Otros servicios recreativos prestados por el sector privado",
                "812110 Salones y clínicas de belleza y peluquerías"
            ],
            gires: [
                "CAFETERÍA Y FARMACIA",
                "CLÍNICA",
                "CLÍNICA, SANATORIO U HOSPITAL",
                "CLÍNICA VETERINARIA CON VENTA DE MASCOTAS, ALIMENTOS ACCESORIOS",
                "CONSULTA, REHABILITACIÓN MUSCULAR Y MASAJES TERAPÉUTICOS",
                "CONSULTORIO DENTAL",
                "CONSULTORIO MÉDICO",
                "CONSULTORIO DE PODOLOGÍA",
                "ESTÉTICA VETERINARIA",
                "ESTÉTICA VETERINARIA",
                "FARMACIA",
                "FARMACIA Y DROGUERÍA",
                "FARMACIA VETERINARIA",
                "FARMACIA Y PERFUMERÍA",
                "LABORATORIO DE ANÁLISIS CLÍNICOS Y QUÍMICOS",
                "ÓPTICA",
                "OTROS CONSULTORIOS",
                "OTROS SERVICIOS TURÍSTICOS",
                "SALA DE BELLEZA, COSMETOLOGÍA Y SPA",
                "UNIDAD RADIOLÓGICA Y DE ULTRASONIDO",
                "Otro"
            ],
            genders: [
              {
                key: "M",
                value: "Masculino"
              },
              {
                key: "F",
                value: "Femenino"
              },
              {
                key: "N",
                value: "Prefiero no decir"
              },
              {
                key: "O",
                value: "Otro"
              }
            ],
            user: {},
            property_name: "",
            first_last_name: "",
            second_lastname: "",
            gender: "",
            other_option: "",
            ret: "",
            rfc: "",
            whatsapp: "",
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            comercial_rotate: "",
            other_option_form: "",
            cian_key: "",
            attention_medic: "",
            bibliographic: "",
            tiktok_profile: "",
            english: "",
            english_level: "",
            professional_organism: "",
            integral_services: "",
            hotel: false,
            transportation: false,
            restaurant: false,
            touroperator: false,
            house: false,
            house1: false,
            broker: false,
            not: false,
            not_interest: false,
            comercial_name: "",
            is_member: "",
            others_name: "",
            is_accredit: "",
            JCI: false,
            CNSG: false,
            GHA: false,
            CEHC: false,
            SRC: false,
            SRCCE: false,
            SRCMS: false,
            SRCCS: false,
            CAMN: false,
            STSM: false,
            STI: false,
            ISO9001: false,
            ISO27001: false,
            ISO45001: false,
            ISO13485: false,
            other_option_select: false,
            repssabi_coepris: "",
            folio_repssabi_coepris: "",
            date_repssabi_coepris: "",
        }
    },
    methods: {
        updateData(value=undefined){
            const d = value??this.entity;
            if(d){
                this.property_name = d.property_name;
                this.first_last_name = d.first_last_name;
                this.second_lastname = d.second_lastname;
                this.gender = d.gender;
                this.other_option = d.other_option;
                this.rfc = d.rfc;
                this.whatsapp = d.whatsapp;
                this.monday = d.monday;
                this.tuesday = d.tuesday;
                this.wednesday = d.wednesday;
                this.thursday = d.thursday;
                this.friday = d.friday;
                this.saturday = d.saturday;
                this.sunday = d.sunday;
                this.comercial_rotate = d.comercial_rotate;
                this.other_option_form = d.other_option_form;
                this.cian_key = d.cian_key;
                this.attention_medic = d.attention_medic;
                this.bibliographic = d.bibliographic;
                this.tiktok_profile = d.tiktok_profile;
                this.english = d.english;
                this.english_level = d.english_level;
                this.professional_organism = d.professional_organism;
                this.integral_services = d.integral_services;
                this.hotel = d.hotel;
                this.transportation = d.transportation;
                this.restaurant = d.restaurant;
                this.touroperator = d.touroperator;
                this.house = d.house;
                this.house1 = d.house1;
                this.broker = d.broker;
                this.not = d.not;
                this.not_interest = d.not_interest;
                this.comercial_name = d.comercial_name;
                this.is_member = d.is_member;
                this.others_name = d.others_name;
                this.is_accredit = d.is_accredit;
                this.JCI = d.JCI;
                this.CNSG = d.CNSG;
                this.GHA = d.GHA;
                this.CEHC = d.CEHC;
                this.SRC = d.SRC;
                this.SRCCE = d.SRCCE;
                this.SRCMS = d.SRCMS;
                this.SRCCS = d.SRCCS;
                this.CAMN = d.CAMN;
                this.STSM = d.STSM;
                this.STI = d.STI;
                this.ISO9001 = d.ISO9001;
                this.ISO27001 = d.ISO27001;
                this.ISO45001 = d.ISO45001;
                this.ISO13485 = d.ISO13485;
                this.other_option_select = d.other_option_select;
                this.repssabi_coepris = d.repssabi_coepris;
                this.folio_repssabi_coepris = d.folio_repssabi_coepris;
                this.date_repssabi_coepris = d.date_repssabi_coepris;
            }
        }
    },
    watch: {
        property_name(value){
            this.$emit('update-data', {property_name: value});
        },
        first_last_name(value){
            this.$emit('update-data', {first_last_name: value})
        },
        second_lastname(value){
            this.$emit('update-data', {second_lastname: value})
        },
        gender(value){
            this.$emit('update-data', {gender: value})
        },
        other_option(value){
            this.$emit('update-data', {other_option: value})
        },
        rfc(value){
            this.$emit('update-data', {rfc: value})
        },
        whatsapp(value){
            this.$emit('update-data', {whatsapp: value})
        },
        monday(value){
            this.$emit('update-data', {monday: value})
        },
        tuesday(value){
            this.$emit('update-data', {tuesday: value})
        },
        wednesday(value){
            this.$emit('update-data', {wednesday: value})
        },
        thursday(value){
            this.$emit('update-data', {thursday: value})
        },
        friday(value){
            this.$emit('update-data', {friday: value})
        },
        saturday(value){
            this.$emit('update-data', {saturday: value})
        },
        sunday(value){
            this.$emit('update-data', {sunday: value})
        },
        comercial_rotate(value){
            this.$emit('update-data', {comercial_rotate: value})
        },
        other_option_form(value){
            this.$emit('update-data', {other_option_form: value})
        },
        cian_key(value){
            this.$emit('update-data', {cian_key: value})
        },
        attention_medic(value){
            this.$emit('update-data', {attention_medic: value})
        },
        bibliographic(value){
            this.$emit('update-data', {bibliographic: value})
        },
        tiktok_profile(value){
            this.$emit('update-data', {tiktok_profile: value})
        },
        english(value){
            this.$emit('update-data', {english: value})
        },
        english_level(value){
            this.$emit('update-data', {english_level: value})
        },
        professional_organism(value){
            this.$emit('update-data', {professional_organism: value})
        },
        integral_services(value){
            this.$emit('update-data', {integral_services: value})
        },
        hotel(value){
            this.$emit('update-data', {hotel: value})
        },
        transportation(value){
            this.$emit('update-data', {transportation: value})
        },
        restaurant(value){
            this.$emit('update-data', {restaurant: value})
        },
        touroperator(value){
            this.$emit('update-data', {touroperator: value})
        },
        house(value){
            this.$emit('update-data', {house: value})
        },
        house1(value){
            this.$emit('update-data', {house1: value})
        },
        broker(value){
            this.$emit('update-data', {broker: value})
        },
        not(value){
            this.$emit('update-data', {not: value})
        },
        not_interest(value){
            this.$emit('update-data', {not_interest: value})
        },
        comercial_name(value){
            this.$emit('update-data', {comercial_name: value})
        },
        is_member(value){
            this.$emit('update-data', {is_member: value})
        },
        others_name(value){
            this.$emit('update-data', {others_name: value})
        },
        is_accredit(value){
            this.$emit('update-data', {is_accredit: value})
        },
        JCI(value){
            this.$emit('update-data', {JCI: value})
        },
        CNSG(value){
            this.$emit('update-data', {CNSG: value})
        },
        GHA(value){
            this.$emit('update-data', {GHA: value})
        },
        CEHC(value){
            this.$emit('update-data', {CEHC: value})
        },
        SRC(value){
            this.$emit('update-data', {SRC: value})
        },
        SRCCE(value){
            this.$emit('update-data', {SRCCE: value})
        },
        SRCMS(value){
            this.$emit('update-data', {SRCMS: value})
        },
        SRCCS(value){
            this.$emit('update-data', {SRCCS: value})
        },
        CAMN(value){
            this.$emit('update-data', {CAMN: value})
        },
        STSM(value){
            this.$emit('update-data', {STSM: value})
        },
        STI(value){
            this.$emit('update-data', {STI: value})
        },
        ISO9001(value){
            this.$emit('update-data', {ISO9001: value})
        },
        ISO27001(value){
            this.$emit('update-data', {ISO27001: value})
        },
        ISO45001(value){
            this.$emit('update-data', {ISO45001: value})
        },
        ISO13485(value){
            this.$emit('update-data', {ISO13485: value})
        },
        other_option_select(value){
            this.$emit('update-data', {other_option_select: value})
        },
        repssabi_coepris(value){
            this.$emit('update-data', {repssabi_coepris: value})
        },
        folio_repssabi_coepris(value){
            this.$emit('update-data', {folio_repssabi_coepris: value})
        },
        date_repssabi_coepris(value){
            this.$emit('update-data', {date_repssabi_coepris: value})
        },
        entity(value){
            this.updateData(value);
        }
    },
    mounted(){
        this.user = this.$store.getters.getUser;
        this.updateData();
    }
}
</script>

<style>
</style>