<template>
    <UpdateDoctor :id="$route.params.id"/>
</template>
<script>
import UpdateDoctor from "@/components/admin/modules/gest-doctor/UpdateDoctor";

export default {
    name: "UpdateDoctorView",
    title: "Actualizar Doctor | Baja California Health Tourism",
    components: {
        UpdateDoctor
    }
}
</script>