<template>
    <UpdatePharmaceutic :id="$route.params.id"/>
</template>
<script>
import UpdatePharmaceutic from "@/components/admin/modules/gest-pharmaceutic/UpdatePharmaceutic";

export default {
    name: "UpdateHospitalView",
    title: "Actualizar Farmacia | Baja California Health Tourism",
    components: {
        UpdatePharmaceutic
    }
}
</script>