/************************************************************/
// Capa de Servicios para el modulo de Alianzas Estrategicas.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
      };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/alliances");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/alliances");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/alliances/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData) {
    await this.reload();
    try {
      return axios.post(this.#baseUrl + "/alliances", formData, {
          headers: this.header
        });
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData) {
    try {
      await this.reload();
      return axios.put(
        `${this.#baseUrl}/alliances/${formData.id}`,
        formData,
        {
          headers: this.header
        }
      );
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      await this.reload();
      let response = await axios.delete(`${this.#baseUrl}/alliances/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(alliance_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("alliance_id", alliance_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/alliances",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(alliance_id, picture_id) {
    if (picture_id) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("alliance_id", alliance_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/alliances",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async sendEmail(profile_id, email) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-email/alliances?id=${profile_id}&email=${email}`
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      valueError = error.response.data.messaje;
    } else if (error.request) {
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();
