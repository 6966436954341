/***********************************************/
// Capa de Servicios para el modulo de Usuarios.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

  constructor() {
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getAllRecords() {
    try {
      await this.reload();
      let response = await axios.get(this.#baseUrl + "/view-list/users", {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      await this.reload();
      let response = await axios.get(`${this.#baseUrl}/users/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  addRecord(formData) {
    this.reload();
    return axios
      .post(this.#baseUrl + "/users", {
        name: formData.name,
        last_name: formData.last_name,
        email: formData.email,
        password: formData.password,
        roles: formData.roles,
        active: formData.active,
      },
      {
        headers: this.header
      });
  }

  // Pushes posts to the server when called.
  updateRecord(formData) {
    this.reload();
    return axios.put(`${this.#baseUrl}/users/${formData.id}`, formData, {
          headers: this.header
        });
  }

  deleteRecord(id) {
    this.reload();
    return axios.delete(`${this.#baseUrl}/users/${id}`, {
          headers: this.header
        })
  }

  getQR2Fa() {
    this.reload();
    return axios.get(`${this.#baseUrl}/qr-user`, {
          headers: this.header
        });
  }

  getQR2FaId(id) {
    this.reload();
    return axios.get(`${this.#baseUrl}/qr-user/${id}`, {
          headers: this.header
        });
  }

  uploadImage(user_id, picture_id, imageFile) {
    if (imageFile) {
      this.reload();
      let formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("picture_id", picture_id);
      formData.append("file", imageFile);
      axios
        .post(this.#baseUrl + "/upload-image/users", formData, {
          headers: this.header
        })
        .then((response) => {
          return response.data;
        })
        /* handle error */
        .catch((error) => {
          return this.handleError(error);
        });
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      valueError = error.response.data.messaje;
    } else if (error.request) {
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    // console.log(error.config);
    return valueError;
  }
})();
