<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
            mdi-account-details
        </v-icon>
        </template>

        <span>Validador</span>

        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" max-width="600px" persistent>
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title class="text-subtitle-1 text-md-h5">Validador</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-btn class="hidden-sm-and-down" icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <div class="custom-validator">
                    <div class="panel panel-default" style="box-shadow: 2px 2px 10px grey;">
                        <div class="panel-heading" style="background-color:#5c2134">
                            <img src="@/assets/img/logobcw.png" width="155px" height="60px"/>
                        </div>
                        <div style="background-color:#ba9f68;color:white">
                            <center>Secretar&iacute;a de Turismo</center>
                        </div>
                        <div class="panel-body" style="display: flex;">
                            <div class="col-sm-4" style="margin-top: 8px; margin-bottom: 10px;">
                                <v-img style="border: 2px solid #6a1b31; width: 95px; height: 100px;" :src="doctor | pictures"/>
                            </div>
                            <div class="col-sm-8">
                                <fieldset class="form-group" style="color: black;">
                                    NOMBRE / NAME:<br><label style="color: #6a1b31; font-size: 10px;" class="control-label">{{doctor.name}}</label>
                                </fieldset>
                                <fieldset class="form-group" style="color: black;">
                                    C&Eacute;DULA PROFESIONAL / LICENSE NUMBER:<br>
                                    <label style="color: #6a1b31; font-size: 10px;" class="control-label">{{doctor.profesional_license_number}}</label>
                                </fieldset>
                                <fieldset class="form-group" style="color: black;">
                                    DIRECCI&Oacute;N / ADDRESS:<br><label style="color: #6a1b31; font-size: 10px;" class="control-label">{{doctor.address}}</label>
                                </fieldset>
                            </div>
                        </div>
                        <div class="panel-body">
                            <div class="col-sm-12 col-lg-12">
                                <v-row class="d-flex">
                                    <v-col cols="6" class="text-center">
                                        <fieldset class="form-group" style="color: black;">
                                            ESPECIALIDAD / MEDICAL SPECIALITY:<br><label style="color: #6a1b31; font-size: 10px;" class="control-label">{{ doctor.specialties | specialtiesNames }}</label>
                                        </fieldset>
                                        <fieldset class="form-group" style="color: black;">
                                            NO. FOLIO / FOLIO:<br><label class="control-label" style="color: #6a1b31; font-size: 12px;" type="date">{{doctor.ret}}</label>
                                        </fieldset>
                                    </v-col>
                                    <v-col cols="6" class="text-center">
                                        <fieldset class="form-group" style="color: black;">
                                            EXPEDIENTE / PROCEEDINGS:<br><label class="control-label" style="color: #6a1b31; font-size: 12px;" type="date">{{ doctor.record }}</label>
                                        </fieldset>
                                        <fieldset class="form-group" style="color: black;">
                                            VIGENCIA / EXPIRATION:<br><label class="control-label" style="color: #6a1b31; font-size: 12px;" type="date">{{ doctor.vencimiento_fecha | dataFormat }}</label>
                                        </fieldset>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <div class="">&nbsp;</div>
                        <div class="center panel-heading " style="background-color:#5c2134">
                            <img src="@/assets/img/logo-turismo.png" width="200px" height="40px"/>
                        </div>
                    </div>
                </div>
            </v-card-text>
            </v-card>
        </v-dialog>      
    </v-tooltip>    
</template>

<script>
export default {
    name: "Validator",
    props: ["doctor"],
    data(){
        return {
            dialog: false,
        }
    },
    filters: {
        specialtiesNames(value){
            let sp = "";
            value.forEach(element => {
                sp = `${sp}, ${element.name}`;
            });
            return sp.substring(1);
        },
        pictures(value){
            return value && value.pictures && value.pictures.length > 0? value.pictures[0].url:"";
        },
        dataFormat(value){
            return value && value.substring(0,10);
        }
    },
    methods: {
        openDialog(){
            this.dialog = true;
        }
    }
}
</script>

<style>
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.panel-default {
    background-image: url(https://gestion.bajacalifornia.gob.mx/image_urbem/img/fondo-right2.png);
    background-size: 110% 110%;
    background-position: center center;
    background-size: cover;
    opacity: 0.9;
    border-color: #ddd;
}

.form-group {
    margin-bottom: 1px;
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
}

.text-center {
    text-align: center !important;
}

.custom-validator {
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: bold;
    color: black;
    box-sizing: border-box;
    display: block;

}

.center {
    text-align: center;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
</style>