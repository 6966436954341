/***********************************************/
// Capa de Servicios para el modulo de carrito.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

  constructor() {
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getAllCarts() {
    try {
      let response = await axios.get(this.#baseUrl + "/shopping-carts");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addCart(formData) {
    await this.reload();
    return axios
      .post(this.#baseUrl + "/add-to-shopping-cart", formData, {
          headers: this.header
        })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return this.handleError(error);
      });
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      valueError = error.response.data.messaje;
    } else if (error.request) {
      valueError = error.request.data;
    } else {
      valueError = error.message;
    }
    return valueError;
  }

  deleteRecord(id) {
    this.reload();
    let result = null;
    axios
      .delete(`${this.#baseUrl}/shopping-carts/${id}`, {
          headers: this.header
        })
      .then((response) => {
        result = response.data;
      })
      /* handle error */
      .catch((error) => {
        result = this.handleError(error);
      });
    return result;
  }

  async updateRecord(id, state) {
    await this.reload();
    let result = null;
    try {
      let response = await axios.patch(
        `${this.#baseUrl}/shopping-carts/${id}/update-state`,
        {
          state: state,
        },
        {
          headers: this.header
        }
      );

      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      result = this.handleError(error);
      return result;
    }
  }
})();
