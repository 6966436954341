import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import titleMixin from "./mixins/titleMixin";
// import * as Sentry from "@sentry/browser";
// import * as Integrations from "@sentry/integrations";
import "./plugins/vuetify-mask.js";
import device from "vue-device-detector-js";
//import VueApexCharts from 'vue-apexcharts';

import axios from "axios";
import VueAxios from "vue-axios";
import { VueDraggable } from 'vue-draggable-plus';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//import { VueReCaptcha } from 'vue-recaptcha-v3';
import 'bootstrap-icons/bootstrap-icons.svg';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './assets/styles.css'; // Importa tu archivo CSS global

// Sentry.init({
//   dsn: "https://910936d55696486a815d25b5296425f4@sentry.idooproject.com/7",
//   integrations: [new Integrations.Vue({ Vue, attachProps: true })],
// });
import * as VueGoogleMaps from 'vue2-google-maps';

const firebaseConfig = {
  apiKey: "AIzaSyC0iHbsLtB_i7WNdSZOOp489sgOjBFhQLI",
  authDomain: "baja-health-tourism.firebaseapp.com",
  projectId: "baja-health-tourism",
  storageBucket: "baja-health-tourism.firebasestorage.app",
  messagingSenderId: "391195797996",
  appId: "1:391195797996:web:6867670867affac722b8c9",
  measurementId: "G-LNCGHDMJ3R"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

console.log(analytics);

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAkvrORWAYI4tCWwQreRsCVdSGwhfBaBX0",
        libraries: 'places',
    },
})
Vue.use(VueAxios, axios);
//Vue.use(VueReCaptcha, { siteKey: process.env.VUE_CODE_RECAPTCHA })
Vue.component("vue-draggable", VueDraggable);
Vue.mixin(titleMixin);
Vue.use(device);
// Vue.use(VueApexCharts);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  // mask,
  render: (h) => h(App),
}).$mount("#app");

if (localStorage.getItem("store")) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(localStorage.getItem("store"))));
}

window.addEventListener("beforeunload",()=>{
    localStorage.setItem("store",JSON.stringify(store.state));
});
