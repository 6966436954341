/********************************************************/
// Capa de Servicios para el modulo de Sub-Especialidades.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/subspecialties");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(
        this.#baseUrl + "/view-list/subspecialties"
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/subspecialties/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  addRecord(formData) {
    this.reload();
    let recordData = {
      id: formData.id,
      name: formData.name,
      name_english: formData.name_english,
      translation_id: formData.translation_id,
      specialty_id: formData.specialty.id,
      enabled: formData.enabled,
    };
    return axios.post(this.#baseUrl + "/subspecialties", recordData, {
      headers: this.header
    });
  }

  // Pushes posts to the server when called.
  updateRecord(id, formData) {
    this.reload();
    let recordData = {
      id: formData.id,
      name: formData.name,
      name_english: formData.name_english,
      translation_id: formData.translation_id,
      specialty_id: formData.specialty.id,
      enabled: formData.enabled,
    };
    return axios.put(`${this.#baseUrl}/subspecialties/${id}`, recordData, {
      headers: this.header
    });
  }

  deleteRecord(id) {
    this.reload();
    return axios.delete(`${this.#baseUrl}/subspecialties/${id}`, {
      headers: this.header
    });
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      valueError = error; // error.response.data.messaje;
    } else if (error.request) {
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();
