<template>
  <div>
    <v-row>
      <v-col
        class="d-none d-md-flex align-center justify-center"
        cols="12"
        lg="7"
        xl="6"
        style="height: 102vh; background-color: #691e32"
      >
        <v-container>
          <div>
            <v-row justify="center">
              <v-col cols="8" xl="5">
                <div>
                  <img
                      src="@/assets/images/logo_blanco.png"
                      style="max-height: 100px"
                  />

                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col class="d-flex align-center" cols="12" lg="5" xl="6">
        <v-container>
          <div class="pa-7 pa-sm-12">
            <v-row>
              <v-col cols="12" lg="9" xl="6">
                <h2
                  class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                >
                  Autenticaci&oacute;n
                </h2>

                <v-form
                  ref="form"
                  v-model="valid"
                  action="/admin/wireframe"
                  lazy-validation
                >
                  <v-otp-input
                    length="6"
                    type="number"
                    v-model="code"
                    :rules="otpRules"
                    ></v-otp-input>

                  <v-btn
                    :disabled="!valid"
                    block
                    class="mr-4"
                    style="background-color: #691e32; color: white"
                    submit
                    :loading="progressDialog"
                    @click="submit"
                  >
                    Entrar
                  </v-btn>
                </v-form>
                <div class="text-center mt-6">
                  <!--mdi-home-circle-->
                  <v-chip v-show="false" class="mr-2" pill @click="goHome">
                    <v-avatar left>
                      <v-btn class="white--text" color="primary lighten-1">
                        <v-icon>mdi-home</v-icon>
                      </v-btn>
                    </v-avatar>
                    Ir al Inicio
                  </v-chip>
                  <div style="height: 70px"></div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="dialogError" 
      color="red accent-2"
      :timeout="2000" 
      rounded="pill"
      absolute
      elevation="24"
      shaped>
      {{ messageError }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="dialogError = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import loginService from "@/providers/LoginService";

export default {
  name: "TwoFactorComponent",
  data: () => ({
    valid: true,
    dialogError: false,
    messageError: "",
    code: "",
    progressDialog: false,
    otpRules: [
        v => !!v || "Campo requerido",
        v => v.toString().length === 6 || "Rellene todos los campos",
    ]
  }),
  watch: {
    dialogError(val) {
      val || this.closeDialogError();
    },
  },
  methods: {
    goHome() {
      this.$store.dispatch("clearState");
      this.$router.push({ path: "/homepage" });
    },
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.progressDialog = true;
        this.doLogin();
      }
    },
    doLogin() {
      loginService.twoFactor(this.code).then((response) => {
        const {success, token, message} = response.data;
        this.dialogError = false;
        if (success) {
          this.closeDialogError();
          sessionStorage.setItem('2fa', token);
          loginService.redirectToDashboard();
        } else {
          this.dialogError = true;
          this.messageError = message;
        }
      }).catch((err)=>{
        this.messageError = err.msg;
        this.dialogError = true;
      }).finally(()=>{
        this.progressDialog = false;
      });
    },
    closeDialogError() {
      this.dialogError = false;
      this.messageError = "";
    },
  },
};
</script>
