<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title class="text-h6 grey lighten-2">
            <span class="headline">A&ntilde;adir cl&iacute;nica</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row class="mt-2">
                <v-col cols="10">
                  <v-text-field v-model="editedItem.name" label="Nombre" :rules="textRules" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                required></v-text-field>
                </v-col>

                <v-col cols="2">
                  <v-checkbox v-model="editedItem.active" label="Activo" v-if="user.roles=='ADMIN'" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-checkbox>
                </v-col>


                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'CEETSB'" cols="5">
                  <v-col cols="12" md="6">
                    <v-select v-model="editedItem.state_ceetsb" dense
                              :items="['Aprobado', 'Pendiente', 'Rechazado']"
                              :rules="[v => !!v || 'Campo es obligatorio']"
                              label="Excelencia"
                              :disabled="saveChanges"
                              item-color="primary"
                              persistent-hint>
                    </v-select>
                  </v-col>
                </v-col>
                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'CEETSB'" cols="7"
                       class="d-flex align-center justify-space-between">
                  <v-text-field dense v-model.number="editedItem.comment_ceetsb"
                                :disabled="editedItem.state_ceetsb !== 'Rechazado' || saveChanges"
                                label="Comentario" class="mt-3"></v-text-field>
                  <v-icon>mdi-file-document</v-icon>
                </v-col>
                <v-col v-if="user.roles === 'COPARMEX'" cols="5">
                  <v-col cols="12" md="6">
                    <v-select v-model="editedItem.state_ceetsb" dense
                              :items="['Aprobado', 'Pendiente', 'Rechazado']"
                              :rules="[v => !!v || 'Campo es obligatorio']"
                              label="Excelencia"
                              :disabled="saveChanges"
                              item-color="primary"
                              persistent-hint>
                    </v-select>
                  </v-col>
                </v-col>
                <v-col v-if="user.roles === 'COPARMEX'" cols="7"
                       class="d-flex align-center justify-space-between">
                  <v-text-field dense v-model.number="editedItem.comment_ceetsb"
                                :disabled="editedItem.state_ceetsb !== 'Rechazado' || saveChanges"
                                label="Comentario" class="mt-3"></v-text-field>
                  <v-icon>mdi-file-document</v-icon>
                </v-col>

                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COEPRIS'" cols="5">
                  <v-col cols="12" md="6">
                    <v-select v-model="editedItem.state_coepris" dense
                              :items="['Aprobado', 'Pendiente', 'Rechazado']"
                              :rules="[v => !!v || 'Campo es obligatorio']"
                              label="Repssabi"
                              :disabled="saveChanges"
                              item-color="primary"
                              persistent-hint>
                    </v-select>
                  </v-col>
                </v-col>
                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COEPRIS'" cols="7"
                       class="d-flex align-center justify-space-between">
                  <v-text-field dense v-model.number="editedItem.comment_coepris"
                                :disabled="editedItem.state_coepris !== 'Rechazado' || saveChanges"
                                label="Comentario" class="mt-3"></v-text-field>
                  <v-icon>mdi-file-document</v-icon>
                </v-col>
                <v-col v-if="user.roles === 'COPARMEX'" cols="5">
                  <v-col cols="12" md="6">
                    <v-select v-model="editedItem.state_coepris" dense
                              :items="['Pendiente', 'Rechazado']"
                              :rules="[v => !!v || 'Campo es obligatorio']"
                              label="Repssabi"
                              :disabled="saveChanges"
                              item-color="primary"
                              persistent-hint>
                    </v-select>
                  </v-col>
                </v-col>
                <v-col v-if="user.roles === 'COPARMEX'" cols="7"
                       class="d-flex align-center justify-space-between">
                  <v-text-field dense v-model.number="editedItem.comment_coepris"
                                :disabled="editedItem.state_coepris !== 'Rechazado' || saveChanges"
                                label="Comentario" class="mt-3"></v-text-field>
                  <v-icon>mdi-file-document</v-icon>
                </v-col>

                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX'" cols="5">
                  <v-col cols="12" md="6">
                    <v-select v-model="editedItem.state_coparmex" dense
                              :items="['Aprobado', 'Pendiente', 'Rechazado']"
                              :rules="[v => !!v || 'Campo es obligatorio']"
                              label="Coparmex"
                              :disabled="saveChanges"
                              item-color="primary"
                              persistent-hint>
                    </v-select>
                  </v-col>
                </v-col>
                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX'" cols="7"
                       class="d-flex align-center justify-space-between">
                  <v-text-field dense v-model.number="editedItem.comment_coparmex"
                                :disabled="editedItem.state_coparmex !== 'Rechazado' || saveChanges"
                                label="Comentario" class="mt-3"></v-text-field>
                  <v-icon>mdi-file-document</v-icon>
                </v-col>

                <v-col cols="12">
                          <v-radio-group v-model="editedItem.typeEntity">
                            <v-row>
                              <v-col cols="4">
                                <v-radio :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                  label="Premium"
                                  value="P"
                                ></v-radio>
                              </v-col>
                              <v-col cols="4">
                                <v-radio :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                  label="Miembro"
                                  value="M"
                                ></v-radio>
                              </v-col>
                              <v-col cols="4">
                                <v-radio :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                  label="Básico"
                                  value="B"
                                ></v-radio>
                              </v-col>
                            </v-row>
                          </v-radio-group>
                        </v-col>

                <v-col cols="4">
                  <v-checkbox v-model="editedItem.excellence" v-if="user.roles == 'CEETSB' || user.roles == 'ADMIN'" label="Excelencia"></v-checkbox>
                </v-col>

                <v-col cols="4">
                  <v-checkbox v-model="editedItem.repsabi" v-if="user.roles == 'COEPRIS' || user.roles == 'ADMIN'" label="Repssabi" :disabled="saveChanges"></v-checkbox>
                </v-col>

                <v-col cols="4">
                  <v-checkbox v-model="editedItem.coparmex" v-if="user.roles == 'COPARMEX' || user.roles == 'ADMIN'" label="Coparmex" :disabled="saveChanges"></v-checkbox>
                </v-col>

                <v-col cols="12">
                  <v-combobox v-model="editedItem.address" :items="places" :loading="loadingPlaces"
                              :search-input.sync="searchQuery" :rules="[
                              (v) =>
                                !!v || 'Dirección de ubicación obligatoria',
                            ]" no-data-text="No hay resultados de búsqueda!" item-text="place_name" no-filter clearable
                              required label="Dirección de residencia" v-on="$listeners" v-on:change="getSelectedItem"
                              :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                              @click:clear="onClear">
                  </v-combobox>
                  <!--<v-autocomplete
                                                    v-model="editedItem.address"
                                                    :items="places"
                                                    :loading="isLoadingPlaces"
                                                    :search-input.sync="searchPlace"
                                                    :rules="[v => !!v || 'Dirección de ubicación obligatoria']"
                                                    no-data-text="No hay resultados de búsqueda!"
                                                    item-text="place_name"
                                                    item-value="place_name"
                                                    no-filter
                                                    clearable
                                                    required
                                                    label="Dirección de residencia"
                                                    v-on="$listeners"
                                                    @click:clear="onClear">
                                            </v-autocomplete>-->
                  <v-text-field v-model="editedItem.email" :rules="emailRules" label="E-mail" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea v-model="editedItem.description" label="Descripción - Español" :rules="textRules" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                              no-resize rows="6" counter required>
                  </v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea v-model="editedItem.description_english" label="Descripción - Inglés" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                              :rules="textRules" no-resize rows="6" counter required>
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field-simplemask v-model="editedItem.phone1" label="Teléfono 1" v-bind:properties="{
                              dense: true,
                              prefix: '+52',
                              suffix: '',
                              readonly: false,
                              disabled: false,
                              outlined: false,
                              clearable: false,
                              placeholder: '',
                              required: true,
                              rules: textRules,
                              disabled: saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')
                            }" v-bind:options="{
                              inputMask: '(###) ###-####',
                              outputMask: '##########',
                              empty: null,
                              applyAfter: false,
                              alphanumeric: false,
                              lowerCase: false,
                            }" v-bind:focus="focus" v-on:focus="focus = false" />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field-simplemask v-model="editedItem.phone2" label="Teléfono 2" v-bind:properties="{
                              dense: true,
                              prefix: '',
                              suffix: '',
                              readonly: false,
                              disabled: false,
                              outlined: false,
                              clearable: false,
                              disabled: saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX'),
                              placeholder: '',
                            }" v-bind:options="{
                              inputMask: '(###) ###-####',
                              outputMask: '##########',
                              empty: null,
                              applyAfter: false,
                              alphanumeric: false,
                              lowerCase: false,
                            }" v-bind:focus="focus" v-on:focus="focus = false" />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.facebook_profile" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                label="Perfil en Facebook"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.instagram_profile" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                label="Perfil en Instagram"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model.number="editedItem.web_page" label="Página Web" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.sanitary_license" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                label="Licencia Sanitaria"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense v-model="editedItem.servicehours" label="Horarios de Atención" :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                :rules="textRules" required></v-text-field>
                </v-col>
                <!--<v-col col="12">
                                            <v-select
                                                    dense
                                                    v-model="editedItem.specialties"
                                                    :items="specialtiesList"
                                                    :rules="[v => !!v || 'Al menos una Especialidad es obligatoria']"
                                                    item-text="name"
                                                    item-value="id"
                                                    return-object
                                                    label="Especialidades"
                                                    multiple
                                                    item-color="primary"
                                                    small-chips
                                                    hint="Selecciones las especialidades del médico"
                                                    persistent-hint>
                                            </v-select>
                                        </v-col>-->
              </v-row>
              <v-row>
                <VideoAdd :videos="editedItem.videos" @updateVideo="updateVideo" action="ADD"></VideoAdd>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="saveChanges" text @click="close">
              Cancelar
            </v-btn>
            <v-btn :disabled="!valid" :loading="saveChanges" color="primary" @click="save">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-card>
    <v-snackbar v-model="snackBar" :timeout="2000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import VideoAdd from "@/components/admin/modules/components/VideoAdd";
import {changeInformationSEO} from "@/providers/KeywordsServices";
import clinicsService from "@/providers/ClinicsService";

export default {
  name: "AddClinic",
  components: {VideoAdd},
  data(){
    return {
      focus: false,
      valid: true,
      snackText: "",
      snackBar: false,
      textRules: [(v) => !!v || "Los datos de este campo son obligatorio!"],
      emailRules: [
        (v) => !!v || "El Correo Electrónico es obligatorio",
        (v) => !/[A-Z,Ñ]+/.test(v) || "No se permiten letras en mayúsculas",
        (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
      ],
      facebookRules: [
        (f) =>
            !f
                ? true
                : this.regexMatchesText("facebook", f) ||
                "Enter a valid facebook url",
      ],
      editedItem: {
        id: 0,
        name: "",
        description: "",
        description_english: "",
        address: "",
        phone1: "",
        phone2: "",
        map_lat: 0.0,
        map_lng: 0.0,
        email: "",
        sanitary_license: "",
        web_page: "",
        facebook_profile: "",
        instagram_profile: "",
        youtube_video: "",
        membership: 0,
        servicehours: "",
        translation_id: 0,
        pictures: [],
        specialties: [],
        certifications: [],
        doctors: [],
        extraservices: [],
        videos: [],
        repsabi: false,
        excellence: false,
        coparmex: false,
        state: "Pendiente",
        comment: "",
        comment_ceetsb: "",
        comment_coparmex: "",
        comment_coepris: "",
        state_ceetsb: "Pendiente",
        state_coparmex: "Pendiente",
        state_coepris: "Pendiente",
        typeEntity: "M",
      },
      defaultItem: {
        id: 0,
        name: "",
        videos: [],
        description: "",
        description_english: "",
        address: "",
        phone1: "",
        phone2: "",
        map_lat: 0.0,
        map_lng: 0.0,
        email: "",
        sanitary_license: "",
        web_page: "",
        facebook_profile: "",
        instagram_profile: "",
        youtube_video: "",
        membership: 0,
        servicehours: "",
        translation_id: 0,
        pictures: [],
        specialties: [],
        certifications: [],
        doctors: [],
        extraservices: [],
        repsabi: false,
        excellence: false,
        comment: "",
        comment_ceetsb: "",
        comment_coparmex: "",
        comment_coepris: "",
        state_ceetsb: "Pendiente",
        state_coparmex: "Pendiente",
        state_coepris: "Pendiente",
        typeEntity: "M",
      },
      user: {},
      saveChanges: false,
      placeNameLimit: 60,
      selectedPlace: {
        place_name: "",
        center: [],
      },
      placesList: [],
      loadingPlaces: false,
      searchQuery: null,
      mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
    }
  },
  filters: {
    formatPhone(value) {
      if (value === null) {
        return "";
      }
      return (
          "(" +
          value.substring(0, 3) +
          ") " +
          value.substring(3, 6) +
          "-" +
          value.substring(6)
      );
    },
  },
  computed: {
    places() {
      let listPlaces = [];
      if (this.placesList) {
        listPlaces = this.placesList.map((entry) => {
          const PlaceName =
              entry.place_name.length > this.placeNameLimit
                  ? entry.place_name.slice(0, this.placeNameLimit) + "..."
                  : entry.place_name;
          console.log("PlaceName: ", PlaceName);
          return Object.assign({}, entry);
        });
      }
      return listPlaces;
    }
  },
  watch: {
    async searchQuery(val) {
      if (val === null || val.length === 0) return;
      let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?types=address&access_token=${this.mapBoxApiKey}`;
      this.loadingPlaces = true;
      fetch(url)
          .then((res) => res.json())
          .then((res) => {
            const { query, features } = res;
            this.query = query;
            this.placesList = features;
          })
          .catch((err) => {
            console.log("Error ejecutando API: ", err);
          })
          .finally(() => (this.loadingPlaces = false));
    },
  },
  methods: {
    updateVideo(videos){
      this.editedItem.videos = videos;
    },
    onClear() {
      this.$emit("input", null);
      this.$emit("clear");
    },
    getSelectedItem(item) {
      this.selectedPlace = Object.assign({}, item);
    },
    close(){
      this.$router.push({
              name: 'ClinicsModule'
            });
    },
    save() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.saveChanges = true;
        if (this.selectedPlace.center && this.selectedPlace.center.length > 0) {
          this.editedItem.address = this.selectedPlace.place_name;
          this.editedItem.map_lng = this.selectedPlace.center[0];
          this.editedItem.map_lat = this.selectedPlace.center[1];
        }
        clinicsService.addRecord(this.editedItem).then((response) => {
          if (response.data.success) {
            this.snackText = "Registro guardado con éxito...";
            this.close();
          }
          else{
            this.snackText = response.data.message;
          }
        }).catch(()=>{
          this.snackText = "Ha ocurrido un error!";
        }).finally(()=>{
          this.snackBar = true;
          this.saveChanges = false;
        });
      }
    }
  },
  beforeCreate() {
    this.editedItem = Object.assign({}, this.defaultItem);
  },
  mounted() {
    this.user = this.$store.getters.getUser;
    changeInformationSEO();
  },
}
</script>

<style scoped>

</style>