<template>
    <AddVineyard/>
</template>
<script>
import AddVineyard from "@/components/admin/modules/gest-vineyard/AddVineyard";

export default {
    name: "AddVineyardView",
    title: "Añadir Viñedo | Baja California Health Tourism",
    components: {
        AddVineyard
    }
}
</script>7