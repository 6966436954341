<template>
    <UpdateHouse :id="$route.params.id"/>
</template>
<script>
import UpdateHouse from "@/components/admin/modules/gest-house/UpdateHouse";

export default {
    name: "UpdateHouseView",
    title: "Actualizar Casa | Baja California Health Tourism",
    components: {
        UpdateHouse
    }
}
</script>