<template>
    <AddHouse/>
</template>
<script>
import AddHouse from "@/components/admin/modules/gest-house/AddHouse";

export default {
    name: "AddHouseView",
    title: "Añadir Casa | Baja California Health Tourism",
    components: {
        AddHouse
    }
}
</script>