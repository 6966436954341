<template>
  <div class="mb-5">
    <v-row>
      <v-col cols="12" v-if="user.roles === 'ADMIN'">
        <v-card class="primary rounded-0" v-if="!loading">
          <v-card-text class="px-5 py-3">
            <div class="d-flex align-center">
              <v-avatar color="white" class="mb-0" size="48">
                <v-icon large color="primary">mdi-face-profile</v-icon>
              </v-avatar>

              <div class="ml-4 mr-1">
                <h5 class="subtitle-1 white--text">
                  Total de Perfiles en la Plataforma
                </h5>
                <h2 class="white--text font-weight-regular">
                  {{ totalProfile }}
                </h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-skeleton-loader v-else type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" v-if="!loading">
        <v-row>
          <v-col cols="12" lg="3" sm="6" v-for="(card, index) in cardProfiles" :revenuecard="card" :key="index"
            class="py-0 mb-3">
            <!-- :class="card.bgcolor" -->
            <!-- :color="getColor()" -->
            <v-card :class="card.bgcolor ?? getColor()" class="rounded-0">
              <v-card-text class="px-2 py-3">
                <div class="d-flex align-center">
                  <v-avatar color="transparent" class="mb-0" size="48">
                    <v-icon large color="white">{{ card.icon }}</v-icon>
                  </v-avatar>

                  <div class="ml-2 mr-1">
                    <h5 class="subtitle-1 white--text text-no-wrap">
                      {{ card.title }}
                    </h5>
                    <h2 class="white--text font-weight-regular">
                      {{ card.count }}
                    </h2>
                  </div>
                  <div class="ml-auto mr-0" v-if="card.checklist && card.checklist.length > 0">
                    <v-btn icon color="white" @click="openDialog">
                      <v-icon>mdi-arrow-down-drop-circle</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loading" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="title font-weight-regular">Entidades pendientes</h3>
      </v-col>
      <v-col sm="12" v-if="!loadingPendings">
        <v-row>
          <v-col cols="12" lg="3" sm="6" v-for="(card, index) in cardPending" :revenuecard="card" :key="index"
            class="py-0 mb-3">
            <!-- :class="card.bgcolor" -->
            <!-- :color="getColor()" -->
            <v-card :class="card.bgcolor" class="rounded-0">
              <v-card-text class="px-2 py-3">
                <div class="d-flex align-center">
                  <v-avatar color="transparent" class="mb-0" size="48">
                    <v-icon large color="white">{{ card.icon }}</v-icon>
                  </v-avatar>

                  <div class="ml-2 mr-1">
                    <h5 class="subtitle-1 white--text text-no-wrap">
                      {{ card.title }}
                    </h5>
                    <h2 class="white--text font-weight-regular">
                      {{ card.count }}
                    </h2>
                  </div>
                  <div class="ml-auto mr-0" v-if="card.checklist && card.checklist.length > 0">
                    <v-btn icon color="white" @click="openDialog(card.title, 'pending')">
                      <v-icon>mdi-arrow-down-drop-circle</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="loadingPendings" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loadingPendings" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loadingPendings" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loadingPendings" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="title font-weight-regular">Entidades aprobadas</h3>
      </v-col>
      <v-col cols="12" v-if="!loadingApprove">
        <v-row>
          <v-col cols="12" lg="3" sm="6" v-for="(card, index) in cardOk" :revenuecard="card" :key="index"
            class="py-0 mb-3">
            <v-card :class="card.bgcolor" class="rounded-0">
              <v-card-text class="px-2 py-3">
                <div class="d-flex align-center">
                  <v-avatar color="transparent" class="mb-0" size="48" style="color: #adf5b0 !important;">
                    <v-icon large style="color: #adf5b0 !important;">{{ card.icon }}</v-icon>
                  </v-avatar>

                  <div class="ml-2 mr-1">
                    <h5 class="subtitle-1 text-no-wrap" style="color: #adf5b0 !important;">
                      {{ card.title }}
                    </h5>
                    <h2 class="font-weight-regular" style="color: #adf5b0 !important;">
                      {{ card.count }}
                    </h2>
                  </div>
                  <div class="ml-auto mr-0" v-if="card.checklist && card.checklist.length > 0">
                    <v-btn icon color="white" @click="openDialog(card.title, 'approve')">
                      <v-icon>mdi-arrow-down-drop-circle</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="loadingApprove" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loadingApprove" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loadingApprove" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
      <v-col v-if="loadingApprove" cols="12" lg="3" sm="6" class="py-0 mb-3">
        <v-skeleton-loader type="image" max-height="75px"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_total" title="Entidades Pendientes totales">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-subtitle-1 text-md-h5">Entidades pendientes a aprobar totales
          </v-toolbar-title>
          <v-toolbar-items>
            <v-select v-model="entity" dense :items=filter label="Tipo de entidad" class="ms-5 mt-5"
              item-color="primary" persistent-hint>
            </v-select>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="hidden-sm-and-down" icon dark @click="dialog_total = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="totalPendientesFiltrado" :items-per-page="10" item-key="id"
            no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: false,
              itemsPerPageAllText: 'Todas',
              itemsPerPageText: 'Filas por página:',
            }">
            <template v-slot:item.premium="{ item }">
              <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.premium))">
                {{ fixStateLabelYesOrNot(item.premium) }}
              </div>
            </template>
            <template v-slot:item.member="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.member))">
                {{ fixStateLabelYesOrNot(item.member) }}
              </div>
            </template>
            <template v-slot:item.basic="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.basic))">
                {{ fixStateLabelYesOrNot(item.basic) }}
              </div>
            </template>
            <template v-slot:item.specialties="{ item }">
              {{ speciality(item.specialties) }}
            </template>
            <template v-slot:item.excellence="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.repsabi))">
                {{ fixStateLabelExtra(item.repsabi) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_coparmex" title="Entidades Pendientes de COPARMEX">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-subtitle-1 text-md-h5">Entidades pendientes a aprobar de COPARMEX
          </v-toolbar-title>
          <v-toolbar-items>
            <v-select v-model="entity" dense :items=filter label="Tipo de entidad" class="ms-5 mt-5"
              item-color="primary" persistent-hint>
            </v-select>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="hidden-sm-and-down" icon dark @click="dialog_coparmex = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="coparmexPendientesFiltrado" :items-per-page="10" item-key="id"
            no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: false,
              itemsPerPageAllText: 'Todas',
              itemsPerPageText: 'Filas por página:',
            }">
            <template v-slot:item.premium="{ item }">
              <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.premium))">
                {{ fixStateLabelYesOrNot(item.premium) }}
              </div>
            </template>
            <template v-slot:item.member="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.member))">
                {{ fixStateLabelYesOrNot(item.member) }}
              </div>
            </template>
            <template v-slot:item.basic="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.basic))">
                {{ fixStateLabelYesOrNot(item.basic) }}
              </div>
            </template>
            <template v-slot:item.specialties="{ item }">
              {{ speciality(item.specialties) }}
            </template>
            <template v-slot:item.excellence="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.repsabi))">
                {{ fixStateLabelExtra(item.repsabi) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_excellence" title="Entidades Pendientes Excelencia">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-subtitle-1 text-md-h5">Entidades pendientes a aprobar de Excelencia
          </v-toolbar-title>
          <v-toolbar-items>
            <v-select v-model="entity" dense :items=filter label="Tipo de entidad" class="ms-5 mt-5"
              item-color="primary" persistent-hint>
            </v-select>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="hidden-sm-and-down" icon dark @click="dialog_excellence = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="excelenciaPendientesFiltrado" :items-per-page="10" item-key="id"
            no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: false,
              itemsPerPageAllText: 'Todas',
              itemsPerPageText: 'Filas por página:',
            }">
            <template v-slot:item.premium="{ item }">
              <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.premium))">
                {{ fixStateLabelYesOrNot(item.premium) }}
              </div>
            </template>
            <template v-slot:item.member="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.member))">
                {{ fixStateLabelYesOrNot(item.member) }}
              </div>
            </template>
            <template v-slot:item.basic="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.basic))">
                {{ fixStateLabelYesOrNot(item.basic) }}
              </div>
            </template>
            <template v-slot:item.specialties="{ item }">
              {{ speciality(item.specialties) }}
            </template>
            <template v-slot:item.excellence="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.repsabi))">
                {{ fixStateLabelExtra(item.repsabi) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_resabi" title="Entidades Pendientes Repssabi">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-subtitle-1 text-md-h5">Entidades pendientes a aprobar de Repssabi
          </v-toolbar-title>
          <v-toolbar-items>
            <v-select v-model="entity" dense :items=filter label="Tipo de entidad" class="ms-5 mt-5"
              item-color="primary" persistent-hint>
            </v-select>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="hidden-sm-and-down" icon dark @click="dialog_resabi = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="resabiPendientesFiltrado" :items-per-page="10" item-key="id"
            no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: false,
              itemsPerPageAllText: 'Todas',
              itemsPerPageText: 'Filas por página:',
            }">
            <template v-slot:item.premium="{ item }">
              <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.premium))">
                {{ fixStateLabelYesOrNot(item.premium) }}
              </div>
            </template>
            <template v-slot:item.member="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.member))">
                {{ fixStateLabelYesOrNot(item.member) }}
              </div>
            </template>
            <template v-slot:item.basic="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.basic))">
                {{ fixStateLabelYesOrNot(item.basic) }}
              </div>
            </template>
            <template v-slot:item.specialties="{ item }">
              {{ speciality(item.specialties) }}
            </template>
            <template v-slot:item.excellence="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.repsabi))">
                {{ fixStateLabelExtra(item.repsabi) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_total_approve" title="Entidades Pendientes totales">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-subtitle-1 text-md-h5">Entidades aprobadas totales
          </v-toolbar-title>
          <v-toolbar-items>
            <v-select v-model="entity" dense :items=filter label="Tipo de entidad" class="ms-5 mt-5"
              item-color="primary" persistent-hint>
            </v-select>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="hidden-sm-and-down" icon dark @click="dialog_total_approve = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="totalAprobadosFiltrado" :items-per-page="10" item-key="id"
            no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: false,
              itemsPerPageAllText: 'Todas',
              itemsPerPageText: 'Filas por página:',
            }">
            <template v-slot:item.premium="{ item }">
              <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.premium))">
                {{ fixStateLabelYesOrNot(item.premium) }}
              </div>
            </template>
            <template v-slot:item.member="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.member))">
                {{ fixStateLabelYesOrNot(item.member) }}
              </div>
            </template>
            <template v-slot:item.basic="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.basic))">
                {{ fixStateLabelYesOrNot(item.basic) }}
              </div>
            </template>
            <template v-slot:item.specialties="{ item }">
              {{ speciality(item.specialties) }}
            </template>
            <template v-slot:item.excellence="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.repsabi))">
                {{ fixStateLabelExtra(item.repsabi) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_coparmex_approve" title="Entidades Pendientes de COPARMEX">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-subtitle-1 text-md-h5">Entidades aprobadas de COPARMEX
          </v-toolbar-title>
          <v-toolbar-items>
            <v-select v-model="entity" dense :items=filter label="Tipo de entidad" class="ms-5 mt-5"
              item-color="primary" persistent-hint>
            </v-select>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="hidden-sm-and-down" icon dark @click="dialog_coparmex_approve = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="coparmexAprobadosFiltrado" :items-per-page="10" item-key="id"
            no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: false,
              itemsPerPageAllText: 'Todas',
              itemsPerPageText: 'Filas por página:',
            }">
            <template v-slot:item.premium="{ item }">
              <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.premium))">
                {{ fixStateLabelYesOrNot(item.premium) }}
              </div>
            </template>
            <template v-slot:item.member="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.member))">
                {{ fixStateLabelYesOrNot(item.member) }}
              </div>
            </template>
            <template v-slot:item.basic="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.basic))">
                {{ fixStateLabelYesOrNot(item.basic) }}
              </div>
            </template>
            <template v-slot:item.specialties="{ item }">
              {{ speciality(item.specialties) }}
            </template>
            <template v-slot:item.excellence="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.repsabi))">
                {{ fixStateLabelExtra(item.repsabi) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_excellence_approve" title="Entidades Pendientes Excelencia">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-subtitle-1 text-md-h5">Entidades aprobadas de Excelencia
          </v-toolbar-title>
          <v-toolbar-items>
            <v-select v-model="entity" dense :items=filter label="Tipo de entidad" class="ms-5 mt-5"
              item-color="primary" persistent-hint>
            </v-select>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="hidden-sm-and-down" icon dark @click="dialog_excellence_approve = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="excelenciaAprobadosFiltrado" :items-per-page="10" item-key="id"
            no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: false,
              itemsPerPageAllText: 'Todas',
              itemsPerPageText: 'Filas por página:',
            }">
            <template v-slot:item.premium="{ item }">
              <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.premium))">
                {{ fixStateLabelYesOrNot(item.premium) }}
              </div>
            </template>
            <template v-slot:item.member="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.member))">
                {{ fixStateLabelYesOrNot(item.member) }}
              </div>
            </template>
            <template v-slot:item.basic="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.basic))">
                {{ fixStateLabelYesOrNot(item.basic) }}
              </div>
            </template>
            <template v-slot:item.specialties="{ item }">
              {{ speciality(item.specialties) }}
            </template>
            <template v-slot:item.excellence="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.repsabi))">
                {{ fixStateLabelExtra(item.repsabi) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_resabi_approve" title="Entidades Pendientes Repssabi">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-subtitle-1 text-md-h5">Entidades aprobadas de Repssabi
          </v-toolbar-title>
          <v-toolbar-items>
            <v-select v-model="entity" dense :items=filter label="Tipo de entidad" class="ms-5 mt-5"
              item-color="primary" persistent-hint>
            </v-select>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="hidden-sm-and-down" icon dark @click="dialog_resabi_approve = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="resabiAprobadosFiltrado" :items-per-page="10" item-key="id"
            no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              disableItemsPerPage: false,
              itemsPerPageAllText: 'Todas',
              itemsPerPageText: 'Filas por página:',
            }">
            <template v-slot:item.premium="{ item }">
              <div class="custom-state-div custom-circle" :style="fixStateColor(fixStateLabelYesOrNot(item.premium))">
                {{ fixStateLabelYesOrNot(item.premium) }}
              </div>
            </template>
            <template v-slot:item.member="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.member))">
                {{ fixStateLabelYesOrNot(item.member) }}
              </div>
            </template>
            <template v-slot:item.basic="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.basic))">
                {{ fixStateLabelYesOrNot(item.basic) }}
              </div>
            </template>
            <template v-slot:item.specialties="{ item }">
              {{ speciality(item.specialties) }}
            </template>
            <template v-slot:item.excellence="{ item }">
              <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.repsabi))">
                {{ fixStateLabelExtra(item.repsabi) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dashBoardService from '@/providers/DashboardService';

export default {
  name: "TheColorSalesCards",
  data: () => ({
    filter: [
      "Todas",
      "Doctor",
      "Hospital",
      "Clínica",
      "Hotel",
      "Casa de recuperación",
      "Laboratorio",
      "Lugares",
      "Farmacia",
      "Restaurant",
      "Transportación",
      "Viñedo"
    ],
    entity: "Todas",
    totalProfile: 0,
    cardProfiles: [],
    cardPending: [],
    cardOk: [],
    pendings: 0,
    doctors: [],
    coparmex_pendind: [],
    resabi_pendind: [],
    excelencia_pendind: [],
    total_pendind: [],
    coparmex_approved: [],
    resabi_approved: [],
    excelencia_approved: [],
    total_approved: [],
    total_count: [],
    user: {},
    dialog_total: false,
    dialog_resabi: false,
    dialog_excellence: false,
    dialog_coparmex: false,
    dialog_total_approve: false,
    dialog_resabi_approve: false,
    dialog_excellence_approve: false,
    dialog_coparmex_approve: false,
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "name",
        width: 260,
      },
      {
        text: "E-mail",
        value: "email"
      },
      {
        text: "Tipo de entidad",
        value: "entidad"
      },
      {
        text: "Premium",
        value: "premium",
        width: 110,
      },
      {
        text: "Miembro",
        value: "member",
        width: 110,
      },
      {
        text: "Básico",
        value: "basic",
        width: 110,
      },
    ],
    loading: true,
    loadingPendings: true, 
    loadingApprove: true,
  }),
  components: {},
  methods: {
    fixStateLabelYesOrNot(state) {
      switch (state !== "") {
        case state !== "":
          if (state === 0) {
            return "No";
          } else if (state === 1) {
            return "Si";
          } else {
            return "N/A";
          }
      }
    },
    fixStateColor(state) {
      switch (state) {
        case 'Rechazado':
          return "background-color: #CA2B2BFF;";
        case 'Pendiente':
          return "background-color: #6E87DBFF;";
        case 'Aprobado':
          return "background-color: #6bc263;";
        case 'Si':
          return "background-color: #6bc263;";
        case 'No':
          return "background-color: #cf7336;";
        default:
          return "background-color: #000;";
      }
    },
    speciality(specialities) {
      let out = "";
      specialities.forEach(e => {
        out = `${out}${e.name}, `;
      })
      return out.substring(0, out.length - 2);
    },
    openDialog(title, status) {
      if (title === "Repssabi" && status === "pending") {
        this.dialog_resabi = true
      }
      if (title === "Excelencia" && status === "pending") {
        this.dialog_excellence = true
      }
      if (title === "Coparmex" && status === "pending") {
        this.dialog_coparmex = true
      }
      if (title === "Total" && status === "pending") {
        this.dialog_total = true
      }
      if (title === "Repssabi" && status === "approve") {
        this.dialog_resabi_approve = true
      }
      if (title === "Excelencia" && status === "approve") {
        this.dialog_excellence_approve = true
      }
      if (title === "Coparmex" && status === "approve") {
        this.dialog_coparmex_approve = true
      }
      if (title === "Total" && status === "approve") {
        this.dialog_total_approve = true
      }
    },
    genRandomIndex(length) {
      return Math.ceil(Math.random() * (length - 1))
    },
    getColor() {
      let colors = [
        "info",
        "success",
        "primary",
        "teal",
        "deep-purple accent2",
        "red lighten-1",
        "pink darken-2",
        "grey darken-2",
        "brown",
        "blue-grey darken-3",
        "red darken-3",
        "green"
      ];
      const colorsLength = colors.length;
      let colorsel = this.genRandomIndex(colorsLength);
      return colors[colorsel];
    },
    fixStateLabelExtra(state) {
      switch (state !== "") {
        case state !== "":
          if (state === 0 || state === "Pendiente") {
            return "Pendiente";
          } else if (state === 1 || state === "Aprobado") {
            return "Aprobado";
          } else {

            return "Rechazado";
          }
      }
    },
    getCardProfiles() {
      this.loading = true;
      this.loadingApprove = true;
      this.loadingPendings = true;
      dashBoardService.getProfiles().then(fechData => {
        this.totalProfile = fechData.total;
        this.cardProfiles = fechData.cards;
        if (this.user.roles === 'COPARMEX') {
          this.cardProfiles = this.cardProfiles.filter(e => e.title === "Por Especialidad");
        } else if (this.user.roles !== 'ADMIN') {
          this.cardProfiles = [];
        }
        dashBoardService.getPendingsEntitiesAll().then(resp => {
          this.coparmex_pendind = [
            ...resp.data.value.coparmex.doctors.map(doctor => ({...doctor, entidad: 'Doctor'})),
            ...resp.data.value.coparmex.hospitals.map(doctor => ({...doctor, entidad: 'Hospital'})),
            ...resp.data.value.coparmex.clinics.map(doctor => ({...doctor, entidad: 'Clínica'})),
            ...resp.data.value.coparmex.hotels.map(doctor => ({...doctor, entidad: 'Hotel'})),
            ...resp.data.value.coparmex.houses.map(doctor => ({...doctor, entidad: 'Casa de recuperación'})),
            ...resp.data.value.coparmex.laboratories.map(doctor => ({...doctor, entidad: 'Laboratorio'})),
            ...resp.data.value.coparmex.leisures.map(doctor => ({...doctor, entidad: 'Lugares'})),
            ...resp.data.value.coparmex.pharmaceutics.map(doctor => ({...doctor, entidad: 'Farmacia'})),
            ...resp.data.value.coparmex.restaurants.map(doctor => ({...doctor, entidad: 'Restaurant'})),
            ...resp.data.value.coparmex.transportations.map(doctor => ({...doctor, entidad: 'Transportación'})),
            ...resp.data.value.coparmex.vineyards.map(doctor => ({...doctor, entidad: 'Viñedo'}))
          ]
          this.excelencia_pendind = [
            ...resp.data.value.ceetsb.doctors.map(doctor => ({...doctor, entidad: 'Doctor'})),
            ...resp.data.value.ceetsb.hospitals.map(doctor => ({...doctor, entidad: 'Hospital'})),
            ...resp.data.value.ceetsb.clinics.map(doctor => ({...doctor, entidad: 'Clínica'})),
            ...resp.data.value.ceetsb.hotels.map(doctor => ({...doctor, entidad: 'Hotel'})),
            ...resp.data.value.ceetsb.houses.map(doctor => ({...doctor, entidad: 'Casa de recuperación'})),
            ...resp.data.value.ceetsb.laboratories.map(doctor => ({...doctor, entidad: 'Laboratorio'})),
            ...resp.data.value.ceetsb.leisures.map(doctor => ({...doctor, entidad: 'Lugares'})),
            ...resp.data.value.ceetsb.pharmaceutics.map(doctor => ({...doctor, entidad: 'Farmacia'})),
            ...resp.data.value.ceetsb.restaurants.map(doctor => ({...doctor, entidad: 'Restaurant'})),
            ...resp.data.value.ceetsb.transportations.map(doctor => ({...doctor, entidad: 'Transportación'})),
            ...resp.data.value.ceetsb.vineyards.map(doctor => ({...doctor, entidad: 'Viñedo'}))
          ]
          this.resabi_pendind = [
            ...resp.data.value.repssabi.doctors.map(doctor => ({...doctor, entidad: 'Doctor'})),
            ...resp.data.value.repssabi.hospitals.map(doctor => ({...doctor, entidad: 'Hospital'})),
            ...resp.data.value.repssabi.clinics.map(doctor => ({...doctor, entidad: 'Clínica'})),
            ...resp.data.value.repssabi.hotels.map(doctor => ({...doctor, entidad: 'Hotel'})),
            ...resp.data.value.repssabi.houses.map(doctor => ({...doctor, entidad: 'Casa de recuperación'})),
            ...resp.data.value.repssabi.laboratories.map(doctor => ({...doctor, entidad: 'Laboratorio'})),
            ...resp.data.value.repssabi.leisures.map(doctor => ({...doctor, entidad: 'Lugares'})),
            ...resp.data.value.repssabi.pharmaceutics.map(doctor => ({...doctor, entidad: 'Farmacia'})),
            ...resp.data.value.repssabi.restaurants.map(doctor => ({...doctor, entidad: 'Restaurant'})),
            ...resp.data.value.repssabi.transportations.map(doctor => ({...doctor, entidad: 'Transportación'})),
            ...resp.data.value.repssabi.vineyards.map(doctor => ({...doctor, entidad: 'Viñedo'}))
          ]
          this.total_pendind = [...this.coparmex_pendind, ...this.resabi_pendind, ...this.excelencia_pendind]
          if (this.user.roles === 'COPARMEX' || this.user.roles === 'ADMIN') {
            this.cardPending = [
              {
                "bgcolor": "wine-bg",
                "title": "Repssabi",
                "rimg": "income-w.png",
                "icon": "mdi-account-clock",
                "count": this.resabi_pendind.length,
                "module": "doctors",
                "checklist": this.resabi_pendind
              },
              {
                "bgcolor": "gold-bg",
                "title": "Excelencia",
                "rimg": "income-w.png",
                "icon": "mdi-account-clock",
                "count": this.excelencia_pendind.length,
                "module": "doctors",
                "checklist": this.excelencia_pendind
              },
              {
                "bgcolor": "blue-white-bg",
                "title": "Coparmex",
                "rimg": "income-w.png",
                "icon": "mdi-account-clock",
                "count": this.coparmex_pendind.length,
                "module": "doctors",
                "checklist": this.coparmex_pendind
              },
              {
                "bgcolor": "dark-bg",
                "title": "Total",
                "rimg": "income-w.png",
                "icon": "mdi-account-clock",
                "count": this.total_pendind.length,
                "module": "doctors",
                "checklist": this.total_pendind
              }
            ];
          }
          if (this.user.roles === 'COEPRIS') {
            this.cardPending = [
              {
                "bgcolor": "wine-bg",
                "title": "Repssabi",
                "rimg": "income-w.png",
                "icon": "mdi-account-clock",
                "count": this.resabi_pendind.length,
                "module": "doctors",
                "checklist": this.resabi_pendind
              }
            ];
          }
          if (this.user.roles === 'CEETSB') {
            this.cardPending = [
              {
                "bgcolor": "gold-bg",
                "title": "Excelencia",
                "rimg": "income-w.png",
                "icon": "mdi-account-clock",
                "count": this.excelencia_pendind.length,
                "module": "doctors",
                "checklist": this.excelencia_pendind
              }
            ];
          }
          this.pendings = resp.data.value.count;
          this.doctors = resp.data.value.coparmex.doctors.map(e => {
            return {
              ...e,
              email: e.email && e.email != null && e.email != "null" ? e.email : "",
              web_page: e.web_page && e.web_page != null && e.web_page != "null" ? e.web_page : "",
              facebook_profile: e.facebook_profile && e.facebook_profile != null && e.facebook_profile != "null" ? e.facebook_profile : "",
              instagram_profile: e.instagram_profile && e.instagram_profile != null && e.instagram_profile != "null" ? e.instagram_profile : "",
              youtube_video: e.youtube_video && e.youtube_video != null && e.youtube_video != "null" ? e.youtube_video : "",
              twitter_profile: e.twitter_profile && e.twitter_profile != null && e.twitter_profile != "null" ? e.twitter_profile : "",
              city: e.city?.name,
            }
          });
        }).catch(err => console.log(err)).finally(()=>{
          this.loadingPendings = false;
        });
        dashBoardService.getApprovedEntitiesAll().then(resp => {
          this.coparmex_approved = [
            ...resp.data.value.coparmex.doctors.map(doctor => ({...doctor, entidad: 'Doctor'})),
            ...resp.data.value.coparmex.hospitals.map(doctor => ({...doctor, entidad: 'Hospital'})),
            ...resp.data.value.coparmex.clinics.map(doctor => ({...doctor, entidad: 'Clínica'})),
            ...resp.data.value.coparmex.hotels.map(doctor => ({...doctor, entidad: 'Hotel'})),
            ...resp.data.value.coparmex.houses.map(doctor => ({...doctor, entidad: 'Casa de recuperación'})),
            ...resp.data.value.coparmex.laboratories.map(doctor => ({...doctor, entidad: 'Laboratorio'})),
            ...resp.data.value.coparmex.leisures.map(doctor => ({...doctor, entidad: 'Lugares'})),
            ...resp.data.value.coparmex.pharmaceutics.map(doctor => ({...doctor, entidad: 'Farmacia'})),
            ...resp.data.value.coparmex.restaurants.map(doctor => ({...doctor, entidad: 'Restaurant'})),
            ...resp.data.value.coparmex.transportations.map(doctor => ({...doctor, entidad: 'Transportación'})),
            ...resp.data.value.coparmex.vineyards.map(doctor => ({...doctor, entidad: 'Viñedo'}))
          ]
          this.excelencia_approved = [
            ...resp.data.value.ceetsb.doctors.map(doctor => ({...doctor, entidad: 'Doctor'})),
            ...resp.data.value.ceetsb.hospitals.map(doctor => ({...doctor, entidad: 'Hospital'})),
            ...resp.data.value.ceetsb.clinics.map(doctor => ({...doctor, entidad: 'Clínica'})),
            ...resp.data.value.ceetsb.hotels.map(doctor => ({...doctor, entidad: 'Hotel'})),
            ...resp.data.value.ceetsb.houses.map(doctor => ({...doctor, entidad: 'Casa de recuperación'})),
            ...resp.data.value.ceetsb.laboratories.map(doctor => ({...doctor, entidad: 'Laboratorio'})),
            ...resp.data.value.ceetsb.leisures.map(doctor => ({...doctor, entidad: 'Lugares'})),
            ...resp.data.value.ceetsb.pharmaceutics.map(doctor => ({...doctor, entidad: 'Farmacia'})),
            ...resp.data.value.ceetsb.restaurants.map(doctor => ({...doctor, entidad: 'Restaurant'})),
            ...resp.data.value.ceetsb.transportations.map(doctor => ({...doctor, entidad: 'Transportación'})),
            ...resp.data.value.ceetsb.vineyards.map(doctor => ({...doctor, entidad: 'Viñedo'}))
          ]
          this.resabi_approved = [
            ...resp.data.value.repssabi.doctors.map(doctor => ({...doctor, entidad: 'Doctor'})),
            ...resp.data.value.repssabi.hospitals.map(doctor => ({...doctor, entidad: 'Hospital'})),
            ...resp.data.value.repssabi.clinics.map(doctor => ({...doctor, entidad: 'Clínica'})),
            ...resp.data.value.repssabi.hotels.map(doctor => ({...doctor, entidad: 'Hotel'})),
            ...resp.data.value.repssabi.houses.map(doctor => ({...doctor, entidad: 'Casa de recuperación'})),
            ...resp.data.value.repssabi.laboratories.map(doctor => ({...doctor, entidad: 'Laboratorio'})),
            ...resp.data.value.repssabi.leisures.map(doctor => ({...doctor, entidad: 'Lugares'})),
            ...resp.data.value.repssabi.pharmaceutics.map(doctor => ({...doctor, entidad: 'Farmacia'})),
            ...resp.data.value.repssabi.restaurants.map(doctor => ({...doctor, entidad: 'Restaurant'})),
            ...resp.data.value.repssabi.transportations.map(doctor => ({...doctor, entidad: 'Transportación'})),
            ...resp.data.value.repssabi.vineyards.map(doctor => ({...doctor, entidad: 'Viñedo'}))
          ]
          this.total_approved = [...this.coparmex_approved, ...this.resabi_approved, ...this.excelencia_approved]
          if (this.user.roles === 'COPARMEX' || this.user.roles === 'ADMIN') {
            this.cardOk = [
              {
                "bgcolor": "wine-bg",
                "title": "Repssabi",
                "rimg": "income-w.png",
                "icon": "mdi-account-check",
                "count": this.resabi_approved.length,
                "module": "doctors",
                "checklist": this.resabi_approved
              },
              {
                "bgcolor": "gold-bg",
                "title": "Excelencia",
                "rimg": "income-w.png",
                "icon": "mdi-account-check",
                "count": this.excelencia_approved.length,
                "module": "doctors",
                "checklist": this.excelencia_approved
              },
              {
                "bgcolor": "blue-white-bg",
                "title": "Coparmex",
                "rimg": "income-w.png",
                "icon": "mdi-account-check",
                "count": this.coparmex_approved.length,
                "module": "doctors",
                "checklist": this.coparmex_approved
              },
              {
                "bgcolor": "dark-bg",
                "title": "Total",
                "rimg": "income-w.png",
                "icon": "mdi-account-check",
                "count": this.total_approved.length,
                "module": "doctors",
                "checklist": this.total_approved
              }
            ];
          }
          if (this.user.roles === 'COEPRIS') {
            this.cardOk = [
              {
                "bgcolor": "wine-bg",
                "title": "Repssabi",
                "rimg": "income-w.png",
                "icon": "mdi-account-check",
                "count": this.resabi_approved.length,
                "module": "doctors",
                "checklist": this.resabi_approved
              }
            ];
          }
          if (this.user.roles === 'CEETSB') {
            this.cardOk = [
              {
                "bgcolor": "gold-bg",
                "title": "Excelencia",
                "rimg": "income-w.png",
                "icon": "mdi-account-check",
                "count": this.excelencia_approved.length,
                "module": "doctors",
                "checklist": this.excelencia_approved
              }
            ];
          }

          this.pendings = resp.data.value.count;
          this.doctors = resp.data.value.coparmex.doctors.map(e => {
            return {
              ...e,
              email: e.email && e.email != null && e.email != "null" ? e.email : "",
              web_page: e.web_page && e.web_page != null && e.web_page != "null" ? e.web_page : "",
              facebook_profile: e.facebook_profile && e.facebook_profile != null && e.facebook_profile != "null" ? e.facebook_profile : "",
              instagram_profile: e.instagram_profile && e.instagram_profile != null && e.instagram_profile != "null" ? e.instagram_profile : "",
              youtube_video: e.youtube_video && e.youtube_video != null && e.youtube_video != "null" ? e.youtube_video : "",
              twitter_profile: e.twitter_profile && e.twitter_profile != null && e.twitter_profile != "null" ? e.twitter_profile : "",
              city: e.city?.name,
            }
          });
        }).catch(err => console.log(err)).finally(() => {
          this.loadingApprove = false;
        });
      }).catch((err)=>console.log(err)).finally(()=>{
        this.loading = false;
      });
    }
  },
  mounted() {
    this.user = this.$store.getters.getUser;
    this.getCardProfiles();
  },
  computed: {
    totalPendientesFiltrado() {
      if (this.entity === 'Todas') {
        return this.total_pendind;
      } else {
        return this.total_pendind.filter(doctor => doctor.entidad === this.entity);
      }
    },
    coparmexPendientesFiltrado() {
      if (this.entity === 'Todas') {
        return this.coparmex_pendind;
      } else {
        return this.coparmex_pendind.filter(doctor => doctor.entidad === this.entity);
      }
    },
    resabiPendientesFiltrado() {
      if (this.entity === 'Todas') {
        return this.resabi_pendind;
      } else {
        return this.resabi_pendind.filter(doctor => doctor.entidad === this.entity);
      }
    },
    excelenciaPendientesFiltrado() {
      if (this.entity === 'Todas') {
        return this.excelencia_pendind;
      } else {
        return this.excelencia_pendind.filter(doctor => doctor.entidad === this.entity);
      }
    },

    totalAprobadosFiltrado() {
      if (this.entity === 'Todas') {
        return this.total_approved;
      } else {
        return this.total_approved.filter(doctor => doctor.entidad === this.entity);
      }
    },
    coparmexAprobadosFiltrado() {
      if (this.entity === 'Todas') {
        return this.coparmex_approved;
      } else {
        return this.coparmex_approved.filter(doctor => doctor.entidad === this.entity);
      }
    },
    resabiAprobadosFiltrado() {
      if (this.entity === 'Todas') {
        return this.resabi_approved;
      } else {
        return this.resabi_approved.filter(doctor => doctor.entidad === this.entity);
      }
    },
    excelenciaAprobadosFiltrado() {
      if (this.entity === 'Todas') {
        return this.excelencia_approved;
      } else {
        return this.excelencia_approved.filter(doctor => doctor.entidad === this.entity);
      }
    },
  }
};
</script>

<style>
.custom-state-div {
  padding: 10px;
  color: #fff;
  border-radius: 20px;
  font-weight: 600;
  text-align: center;
  width: max-content;
  justify-self: center;
}

.picture-list {
  border-radius: 10px;
  max-width: 250px;
}

.dark-bg {
  background-color: rgba(0, 0, 0, 0.91) !important;
}

.wine-bg {
  background-color: rgba(98, 5, 31, 0.91) !important;
}

.gold-bg {
  background-color: rgba(211, 171, 13, 0.91) !important;
}

.blue-white-bg {
  background-color: rgba(3, 57, 110, 0.91) !important;
}

.dark-bg-green {
  background-color: rgba(0, 0, 0, 0.91) !important;
}

.wine-bg-green {
  background-color: rgba(98, 5, 31, 0.91) !important;
}

.gold-bg-green {
  background-color: rgba(211, 171, 13, 0.91) !important;

}

.blue-white-bg-green {
  background-color: rgba(3, 57, 110, 0.91) !important;
}
</style>
