<template>
    <AddRestaurant/>
</template>
<script>
import AddRestaurant from "@/components/admin/modules/gest-restaurant/AddRestaurant";

export default {
    name: "AddRestaurantView",
    title: "Añadir Restaurante | Baja California Health Tourism",
    components: {
        AddRestaurant
    }
}
</script>7