/***********************************************/
// Capa de Servicios para el modulo de Ciudades.

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

    constructor() {
        this.#baseUrl = store.getters.getBaseURL;
    }

    async reload() {
        this.header = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Token-Google-Authenticator': sessionStorage.getItem('2fa')
        };
    }

    async getProfiles() {
        try {
            await this.reload();
            let response = await axios.get(this.#baseUrl + "/profiles", {
                headers: this.header
            });
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getMembership() {
        try {
            await this.reload();
            let response = await axios.get(this.#baseUrl + "/get-total-entities/dashboard", {
                headers: this.header
            });
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
        }
    }
    async getCountRecords(module) {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/count-records/${module}`, {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async delete(item) {
        try {
            await this.reload();
            let response = await axios.delete(`${this.#baseUrl}/contacts/${item.id}`, {
                headers: this.header
            });
            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async deleteComment(item) {
        try {
            await this.reload();
            let response = await axios.delete(`${this.#baseUrl}/comments/${item.id}`, {
                headers: this.header
            });
            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getPendingsEntities() {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/get-type-entities/dashboard`, {
                headers: this.header
            });
            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }
    async getPendingsEntitiesAll() {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/pending-all/dashboard`, {
                headers: this.header
            });
            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }
    async getApprovedEntitiesAll() {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/approved-all/dashboard`, {
                headers: this.header
            });
            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getPendingsRepssabiCoparmexCeetsb() {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/pending-reppsabi-coparmex-ceetsb/dashboard`, {
                headers: this.header
            });
            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getStadisticEntities() {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/get-total-entities/dashboard`, {
                headers: this.header
            });
            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getHistorialChangesEntities() {
        try {
            await this.reload();
            return axios.get(`${this.#baseUrl}/history/dashboard`, {
                headers: this.header
            });
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getTotalDoctorsSpecialties() {
        try {
            await this.reload();
            let response = await axios.get(this.#baseUrl + "/count-records/specialties", {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getCountRecordsTags() {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/count-records/preferences`,{
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getCountTags(tag_type) {
        try {
            await this.reload();
            let formData = new FormData();
            formData.append("tag", tag_type);
            let response = await axios.get(`${this.#baseUrl}/count-tag/preferences?tag=${tag_type}`, {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getTotaltVisits() {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/total-visits/visits`, {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getCountVisits(device_type) {
        try {
            await this.reload();
            let formData = new FormData();
            formData.append("device", device_type);
            let response = await axios.get(`${this.#baseUrl}/count-visits/visits?device=${device_type}`, {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecientContacts() {
        try {
            await this.reload();
            let response = await axios.get(this.#baseUrl + "/view-list-recents/contacts", {
                headers: this.header
            });
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecientComments() {
        try {
            await this.reload();
            let response = await axios.get(this.#baseUrl + "/view-list-recents/comments", {
                headers: this.header
            });
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

}
