<template>
    <div>
        <v-text-field v-model="searchText" label="Search" @input="performSearch" clearable></v-text-field>
    </div>
</template>

<script>
export default {
    name: "SearchBarDoctor",

    props: {
        premiums: Array,
        outstandings: Array,
        members: Array,
        basics: Array
    },

    data() {
        return {
            searchText: "",
        };
    },
    methods: {

        performSearch() {
            this.$emit("search", {
                premiums: this.premiums.filter(e=>e.name.includes(this.searchText)),
                outstandings: this.outstandings.filter(e => e.name.includes(this.searchText)),
                members: this.members.filter(e => e.name.includes(this.searchText)),
                basics: this.basics.filter(e => e.name.includes(this.searchText))
            });
        },
    },
};
</script>
