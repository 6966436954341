<template>
    <UpdateVineyard :id="$route.params.id"/>
</template>
<script>
import UpdateVineyard from "@/components/admin/modules/gest-vineyard/UpdateVineyard";

export default {
    name: "UpdateVineyardView",
    title: "Actualizar Viñedo | Baja California Health Tourism",
    components: {
        UpdateVineyard
    }
}
</script>