<template>
    <AddTransportation/>
</template>
<script>
import AddTransportation from "@/components/admin/modules/gest-transportation/AddTransportation";

export default {
    name: "AddTransportationView",
    title: "Añadir transporte | Baja California Health Tourism",
    components: {
        AddTransportation
    }
}
</script>7