<template>
    <AddLeisure/>
</template>
<script>
import AddLeisure from "@/components/admin/modules/gest-leisure/AddLeisure";

export default {
    name: "AddLeisureView",
    title: "Añadir Lugar | Baja California Health Tourism",
    components: {
        AddLeisure
    }
}
</script>7