<template>
    <UpdateHotel :id="$route.params.id"/>
</template>
<script>
import UpdateHotel from "@/components/admin/modules/gest-hotel/UpdateHotel";

export default {
    name: "UpdateHotelView",
    title: "Actualizar Hotel | Baja California Health Tourism",
    components: {
        UpdateHotel
    }
}
</script>