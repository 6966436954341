<template>
  <v-container fluid fill-height style="height: 70px; background-color: rgba(206,205,205,0)">
    <div class="footer-copyrigth">
      <img
          alt="TurismoBC Logo"
          class="shrink hidden-sm-and-down footer-logo"
          :src="company.logo_url != null ? company.logo_url : require('@/assets/images/no-image.jpg')"
      />
      <span class="text-body-2 text--darken-1">
      &copy;{{ new Date().getFullYear() }}. All rights reserved. Developed and designed by
        <a target="_blank" href="https://www.idoogroup.com">IdooGroup</a>.
    </span>
    </div>
  </v-container>
</template>

<script>
import companyService from "@/providers/CompanyService";

export default {
  name: "FooterProfileComponent",
  data: () => ({
    company: {
      id: 0,
      name: "",
      address: "",
      email: "",
      web_page: "",
      phone1: "",
      phone2: "",
      facebook_profile: "",
      instagram_profile: "",
      youtube_video: "",
      logo_url: "",
    },
  }),
  methods: {
    getCompany() {
      companyService.getViewData().then((record) => {
        if (record.value !== null) {
          this.company = record.value;
        }
      });
    },
  },
  beforeMount() {
    this.getCompany();
  },
};
</script>

<style scoped>
.backcolor {
  background-color: rgba(21, 27, 41, 1);
}

.v-btn {
  text-transform: none !important;
}

.footer-copyrigth {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  object-fit: contain;
  max-width: 150px;
  max-height: 40px;
  height: 50px;
  width: auto;
}

@media (max-width: 767px) {
  .footer-logo {
    display: none;
  }
  .footer-copyrigth {
    width: 90%;
  }
}
</style>
