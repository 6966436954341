<template>
    <AddLaboratory/>
</template>
<script>
import AddLaboratory from "@/components/admin/modules/gest-laboratory/AddLaboratory";

export default {
    name: "AddHospitalView",
    title: "Añadir Laboratorio | Baja California Health Tourism",
    components: {
        AddLaboratory
    }
}
</script>