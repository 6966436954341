<template>
    <AddPharmaceutic/>
</template>
<script>
import AddPharmaceutic from "@/components/admin/modules/gest-pharmaceutic/AddPharmaceutic";

export default {
    name: "AddPharmaceuticView",
    title: "Añadir Farmacia | Baja California Health Tourism",
    components: {
        AddPharmaceutic
    }
}
</script>7