import { render, staticRenderFns } from "./SearchBarDoctor.vue?vue&type=template&id=3a700f74"
import script from "./SearchBarDoctor.vue?vue&type=script&lang=js"
export * from "./SearchBarDoctor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports