import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async reload() {
        this.header = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Token-Google-Authenticator': sessionStorage.getItem('2fa')
        };
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/jointobhc");
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/jointobhc/${id}`);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(data) {
        await this.reload();
        try {
            let formData = new FormData();
            formData.append("name", data.name);
            formData.append("phone", data.phone);
            formData.append("email", data.email);
            formData.append("company", data.company);
            // formData.append("comment", data.comment);
            let response = await axios.post(this.#baseUrl + "/jointobhc", formData, {
                headers: this.header
            });
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            let result;
            result = this.handleError(error);
            return result;
        }
    }

    // Pushes posts to the server when called.
    updateRecord(id, formData) {
        this.reload();
        let result = null;
        axios.put(`${this.#baseUrl}/jointobhc/${id}`, formData, {
          headers: this.header
        })
            .then(response => {
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    result = this.handleError(error);
                }
            );
        return result;
    }

    async attendRequest(data) {
        try {
            await this.reload();
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("attended", data.attended);
            let response = await axios.post(this.#baseUrl + '/register-request/jointobhc', formData, {
                headers: this.header
            });
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            let result;
            result = this.handleError(error);
            return result;
        }
    }

    deleteRecord(id) {
        this.reload();
        let result = null;
        axios.delete(`${this.#baseUrl}/jointobhc/${id}`, {
          headers: this.header
        })
            .then(response => {
                console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    result = this.handleError(error);
                }
            );
        return result;
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error.response.data.messaje;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            valueError = error.message;
        }
        return valueError;
    }
}