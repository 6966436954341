/********************************************/
// Capa de Servicios para el modulo de Blogs.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/blogs");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/blogs");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewListPosts() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list-posts/blogs");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewListRecents() {
    try {
      let response = await axios.get(
        this.#baseUrl + "/view-list-recents/blogs"
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/blogs/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData, picture_id, imageFile) {
    try {
      await this.reload();
      let categoriesList = formData.categories;
      let response = await axios.post(this.#baseUrl + "/blogs", formData, {
          headers: this.header
        });
      if (response.data.success) {
        let blog_id = response.data.id;
        this.uploadImage(blog_id, picture_id, imageFile);

        // Sincroniza las categorias con el Post
        let categoriesIds = [];
        categoriesList.forEach((item) => {
          categoriesIds.push(item.id);
        });
        this.registerCategories(blog_id, categoriesIds);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData, picture_id, imageFile) {
    try {
      await this.reload();
      let result = null;
      let categoriesList = formData.categories;
      let response = await axios.put(
        `${this.#baseUrl}/blogs/${formData.id}`,
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        result = response.data;
        let blog_id = result.id;
        this.uploadImage(blog_id, picture_id, imageFile);

        // Sincroniza las categorias con el post
        let categoriesIds = [];
        categoriesList.forEach((item) => {
          categoriesIds.push(item.id);
        });
        if (categoriesIds.length > 0) {
          this.registerCategories(blog_id, categoriesIds);
        }
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      await this.reload();
      let response = await axios.delete(`${this.#baseUrl}/blogs/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async registerCategories(blog_id, categoriesIds) {
    try {
      await this.reload();
      let formData = new FormData();
      formData.append("blog_id", blog_id);
      formData.append("categories_ids", categoriesIds);
      let response = await axios.post(
        this.#baseUrl + "/register-categories/blogs",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(blog_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("blog_id", blog_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/blogs",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(blog_id, picture_id) {
    if (picture_id) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("blog_id", blog_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/blogs",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();
