<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="9">
          <v-text-field :rules="rulesVideo" v-model="video" :disabled="saveChanges || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')" label="Url del video (*)" required></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn :disabled="saveChanges || !valid || !(user.roles == 'ADMIN' || user.roles == 'COPARMEX')" color="primary" :loading="saveChanges"
                @click="saveVideo">
            A&ntilde;adir
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="9" class="font-weight-bold">Video</v-col>
      <v-col cols="3" class="font-weight-bold">Acciones</v-col>
    </v-row>
    <v-row v-for="(item, index) in videosCurrent" :key="index">
      <v-col cols="9">{{item.url?item.url:item}}</v-col>
      <v-col cols="3">
        <v-tooltip top v-if=" user.roles == 'ADMIN' || user.roles == 'COPARMEX'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" v-on:click="deleteVideo(item)">
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "VideoAdd",
  props: {
    videos : {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    action : {
      type: String,
      default: "ADD"
    }
  },
  data() {
    return {
      video: "",
      saveChanges: false,
      videosCurrent: [],
      valid: true,
      user: {},
      rulesVideo: [
        v => !!v || 'Campo requerido',
        v => /^https:\/\/youtube\.com\/embed\/[a-zA-Z0-9_-]+$/i.test(v) || 'Formato de url incorrecto'
      ]
    }
  },
  watch: {
    videos(value){
      this.videosCurrent = value;
    }
  },
  methods: {
    saveVideo() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        if(this.action == "ADD"){
          this.videosCurrent = [...this.videosCurrent, this.video];
        }
        else {
          this.videosCurrent = [...this.videosCurrent, {
            id: null,
            url: this.video
          }];
        }
        this.$emit('updateVideo', this.videosCurrent);
        this.video = "";
      }
    },
    deleteVideo(item) {
      this.videosCurrent = this.videosCurrent.filter(e=>e !== item);
      this.$emit('updateVideo', this.videosCurrent);
    },
  },
  mounted(){
    this.videosCurrent = this.videos;
    this.user = this.$store.getters.getUser;
  }
}
</script>

<style scoped>

</style>
