import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

    constructor() {
        this.#baseUrl = store.getters.getBaseURL;
    }

    async reload() {
        this.header = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Token-Google-Authenticator': sessionStorage.getItem('2fa')
        };
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/cities");
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/cities/${id}`);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    addRecord(formData) {
        this.reload();
        return axios.post(this.#baseUrl + "/cities", formData,{
          headers: this.header
        });
    }

    // Pushes posts to the server when called.
    updateRecord(id, formData) {
        this.reload();
        return axios.put(`${this.#baseUrl}/cities/${id}`, formData, {
          headers: this.header
        })
    }

    async deleteRecord(id) {
        await this.reload();
        return axios.delete(`${this.#baseUrl}/cities/${id}`, {
          headers: this.header
        });
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        return valueError;
    }
}