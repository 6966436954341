import { render, staticRenderFns } from "./HotelsModuleComponent.vue?vue&type=template&id=dc9e204e&scoped=true"
import script from "./HotelsModuleComponent.vue?vue&type=script&lang=js"
export * from "./HotelsModuleComponent.vue?vue&type=script&lang=js"
import style0 from "./HotelsModuleComponent.vue?vue&type=style&index=0&id=dc9e204e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc9e204e",
  null
  
)

export default component.exports