<template>
    <v-container class="d-flex">
        <div class="d-flex mr-auto ml-auto">
            <v-btn icon plain :disabled="!urlPrev || loading" @click="loadData(urlPrev)" class="mt-auto mb-auto mr-3">
                <v-icon>mdi-skip-previous</v-icon>
            </v-btn>
            <v-select :items="[10, 15, 20]" v-model="cant" class="select-custom" :disabled="loading">
            </v-select>
            <v-btn icon plain :disabled="!urlNext || loading" @click="loadData(urlNext)" class="mt-auto mb-auto ml-3">
                <v-icon>mdi-skip-next</v-icon>
            </v-btn>
        </div>
        <v-snackbar v-model="snackBar" :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" icon @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
import {paginationServices} from "@/providers/PaginationService";

export default {
    name: "Pagination",
    props: {
        url: {
            type: String,
            required: true
        },
        filterPendings: {
            type: Boolean,
            required: false,
            default: false
        },
        reload: {
            type: Boolean,
            required: false,
            default: false
        },
        search: {
            type: String,
            required: false,
            default: "",
        }
    },
    data(){
        return {
            snackText: "",
            snackBar: false,
            urlNext: null,
            urlPrev: null,
            lastUrl: null,
            loading: false,
            cant: 10
        };
    },
    methods: {
        loadData(url){
            this.loading = true;
            this.$emit('loading-data', this.loading);
            this.lastUrl = url;
            paginationServices(url, this.filterPendings, this.cant, this.search).then((resp)=>{
                const {value, success, pendings} = resp.data;
                if(success){
                    this.$emit('load-data', value.data);
                    this.$emit('pendings-data', pendings);
                    this.urlNext = value.next_page_url;
                    this.urlPrev = value.prev_page_url;
                }
                else{
                    this.snackText = 'Ha ocurrido un error!';
                    this.snackBar = true;
                }
            }).catch(()=>{
                this.snackText = 'Ha ocurrido un error!';
                this.snackBar = true;
            }).finally(()=>{
                this.loading = false;
                this.$emit('loading-data', this.loading);
            })
        }
    },
    watch: {
        search(value){
            console.log(value);
            this.loadData(this.url);
        },
        filterPendings(value){
            console.log(value);
            this.loadData(this.url);
        },
        cant(value){
            console.log(value)
            this.loadData(this.url);
        },
        reload(value){
            console.log(value);
            this.loadData(this.lastUrl);
        },
    },
    mounted(){
        this.loadData(this.url);
    }
}
</script>
<style>
.select-custom{
    max-width: 3.3rem;
}
</style>