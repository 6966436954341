<template>
    <AddDoctor/>
</template>
<script>
import AddDoctor from "@/components/admin/modules/gest-doctor/AddDoctor";

export default {
    name: "AddDoctorView",
    title: "Añadir Doctor | Baja California Health Tourism",
    components: {
        AddDoctor
    }
}
</script>