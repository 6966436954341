<template>
    <AddHotel/>
</template>
<script>
import AddHotel from "@/components/admin/modules/gest-hotel/AddHotel";

export default {
    name: "AddHotelView",
    title: "Añadir Hotel | Baja California Health Tourism",
    components: {
        AddHotel
    }
}
</script>7