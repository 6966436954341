/****************************************************/
// Capa de Servicios para el modulo de Especialidades.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/specialties");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getData() {
    try {
      let response = await axios.get(this.#baseUrl + "/view/specialties");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/specialties");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/specialties/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData, picture_id, imageFile) {
    await this.reload();
    try {
      let response = await axios.post(
        this.#baseUrl + "/specialties",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        let data = response.data;
        let specialty_id = data.id;
        await this.uploadImage(specialty_id, picture_id, imageFile);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData, picture_id, imageFile) {
    try {
      await this.reload();
      let response = await axios.put(
        `${this.#baseUrl}/specialties/${formData.id}`,
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        let data = response.data;
        let specialty_id = data.id;
        await this.uploadImage(specialty_id, picture_id, imageFile);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    await this.reload();
    try {
      let response = await axios.delete(`${this.#baseUrl}/specialties/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  uploadImage(specialty_id, picture_id, imageFile) {
    if (imageFile) {
      this.reload();
      let formData = new FormData();
      formData.append("specialty_id", specialty_id);
      formData.append("picture_id", picture_id);
      formData.append("file", imageFile);
      axios
        .post(this.#baseUrl + "/upload-image/specialties", formData, {
          headers: this.header
        })
        .then((response) => {
          return response.data;
        })
        /* handle error */
        .catch((error) => {
          return this.handleError(error);
        });
    }
  }

  async deleteImage(specialty_id, picture_id) {
    try {
      if (picture_id) {
        await this.reload();
        let formData = new FormData();
        formData.append("specialty_id", specialty_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/specialties",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();
