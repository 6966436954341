<template>
    <ValidatorPortal/>
</template>
<script>
import ValidatorPortal from "@/components/validator-portal/ValidatorPortal";

export default {
    name: "ValidatorView",
    title: "Validador | Baja California Health Tourism",
    components: {
        ValidatorPortal
    }
}
</script>
