<template>
    <UpdateTransportation :id="$route.params.id"/>
</template>
<script>
import UpdateTransportation from "@/components/admin/modules/gest-transportation/UpdateTransportation";

export default {
    name: "UpdateTransportationView",
    title: "Actualizar transporte | Baja California Health Tourism",
    components: {
        UpdateTransportation
    }
}
</script>