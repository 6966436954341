<template>
    <v-card class="rounded-lg hotel-card" elevation="10" height="auto" hover outlined width="100%"
        v-on:click="showProfile(item.slu)">
        <v-img :lazy-src="require('@/assets/images/no-image.jpg')" :src="item.pictures_portal[0] != null
                ? item.pictures_portal[0].url
                : require('@/assets/images/no-image.jpg')
            " class="white--text align-end justify-start zoom-image"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)" height="300" position="center">
            <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                    <v-progress-circular color="grey lighten-5" indeterminate>
                    </v-progress-circular>
                </v-row>
            </template>

            <div class="white--text mx-2">
                <p class="text-left font-weight-light text-h5">
                    {{ item.name }}
                </p>
            </div>
        </v-img>
        <img v-if="item.typeEntity == 'P'" src="@/assets/images/icons/premium.png" alt="sello"
            class="superior-derecha" />
        <img v-else-if="item.typeEntity == 'M'" src="@/assets/images/icons/member.png" alt="sello"
            class="superior-derecha" />
        <img v-else-if="item.typeEntity == 'B'" src="@/assets/images/icons/basic.png" alt="sello"
            class="superior-derecha" />
        <img v-if="item.coparmex === 1" src="@/assets/images/icons/coparmex.png" alt="sello1"
            class="superior-derecha-segunda" />
        <img v-if="item.prevented === 1" src="@/assets/images/icons/prevented.jpeg" alt="sello1"
            class="superior-derecha-tercera" />
    </v-card>
</template>
<script>
export default {
    name: "CardHotel",
    props: ["item"],
    methods: {
        showProfile(idHotel) {
            this.selectedId = idHotel;
            this.$router.push({
                name: "HotelProfile",
                params: {
                    id: idHotel,
                },
            });
        },
    }
}
</script>
<style>
.hotel-card {
    border-radius: 8px !important;
}
.top-left {
    position: absolute;
    top: 3px;
    left: 3px;
    width: auto;
    height: 65px;
    border-radius: 50% !important;
}
.superior-derecha {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 80px;
    height: 80px;
}

.superior-derecha-segunda {
    position: absolute;
    top: 73px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50% !important;
}

.superior-derecha-tercera {
    position: absolute;
    top: 145px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50% !important;
}

.superior-derecha-cuarta {
    position: absolute;
    top: 230px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50%;
}
</style>
