<template>
    <UpdateLeisure :id="$route.params.id"/>
</template>
<script>
import UpdateLeisure from "@/components/admin/modules/gest-leisure/UpdateLeisure";

export default {
    name: "UpdateLeisureView",
    title: "Actualizar Lugar | Baja California Health Tourism",
    components: {
        UpdateLeisure
    }
}
</script>