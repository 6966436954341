/********************************************/
// Capa de Servicios para el modulo de Blogs.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

  constructor() {
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getAllCarousel() {
    await this.reload();
    try {
      let response = await axios.get(this.#baseUrl + "/carousel", {
        headers: this.header
      });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getCarousel() {
    try {
      let response = await axios.get(this.#baseUrl + "/carousel-portal");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async add(file) {
    await this.reload();
    try {
      let recordData = new FormData();
      recordData.append("file", file)
      let response = await axios.post(this.#baseUrl + "/carousel", recordData, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async updatePosition(datas) {
    await this.reload();
    try {
      // console.log('Deleting Record: %s ', id);
      let response = await axios.put(`${this.#baseUrl}/carousel-position`, {positions: datas}, {
          headers: this.header
        });
      if (response.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async delete(id) {
    await this.reload();
    try {
      // console.log('Deleting Record: %s ', id);
      let response = await axios.delete(`${this.#baseUrl}/carousel/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      // console.log('Error response: %s', error.response.data.message);
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
      // console.log(error.request);
      // console.error('Error request: ', error.request.data);
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
      // console.log('Error', error.message);
    }
    // console.log(error.config);
    return valueError;
  }
})();
