import axios from "axios";
import store from "@/store/store.js";


export const paginationServices = (url, pendings = false, cant=10, search="")=>{
    const urlServices = url.startsWith(store.getters.getBaseURL)?url: `${store.getters.getBaseURL}/${url}`;
    return axios.get(urlServices, {
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                'Token-Google-Authenticator': sessionStorage.getItem('2fa')
            },
            params: {
                pending: pendings,
                per_page: cant,
                search: search
            }
          });
}